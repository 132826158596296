import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 400px);
  left: calc(50vw - 500px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 936px;
  overflow: hidden;
  max-height: 900px;
  overflow-y: scroll;
`;

const Container = styled.div`
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  position: relative;
`;

const ContentsTxt = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 80px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`;

const Count = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
`;

const Input = styled.input`
  background-color: #f8f8f8;
  height: 65px;
  padding: 0px 20px;
  border: none;
  outline: none;
  ::placeholder {
    color: "#a7a7a7";
    font-weight: 600;
  }
`;

const ProductInput = styled(Input)`
  width: 473px;
`;

const DateInput = styled(Input)`
  width: 293px;
  margin-right: 20px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddBtn = styled.div`
  width: 110px;
  height: 47px;
  border: 1px solid #2282e9;
  color: #2282e9;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 50px;
`;

const Btn = styled.div`
  width: 100%;
  height: 76px;
  background-color: #2282e9;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

function ManagementModal(props: any) {
  const { register, control, getValues, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "codes",
  });

  function onClick() {
    const formData = new FormData();

    const codesValue = getValues("codes");
    const inputArray: { code: string; expiredAt: string }[] = codesValue;

    for (let i = 0; i < inputArray.length; i++) {
      formData.append("gifticonCodes[" + i + "].code", inputArray[i].code);

      formData.append(
        "gifticonCodes[" + i + "].expiredAt",
        inputArray[i].expiredAt
      );
    }

    formData.append("count", inputArray.length + "");

    props.CallAPI(formData);
  }

  useEffect(() => {
    if (props.excel) {
      setValue("codes", props.excel.data.contents);
    }
  }, []);
  return (
    <ModalBg>
      <ModalBox>
        <Container>
          <FontAwesomeIcon
            icon={faX}
            style={{
              position: "absolute",
              right: "50px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            onClick={() => props.close()}
          />
          <ContentsTxt>재고 직접 입력</ContentsTxt>
          <Count>총 {fields.length}건</Count>
          <Inner>
            {fields.map((item, index) => {
              return (
                <InputContainer key={item.id}>
                  <ProductInput
                    placeholder="제품 코드"
                    {...register(`codes.${index}.code`)}
                  />
                  <DateInput
                    placeholder="코드 만료일 ex) 2024-01-05"
                    {...register(`codes.${index}.expiredAt`)}
                  />
                  <FontAwesomeIcon
                    icon={faX}
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#a7a7a7",
                      cursor: "pointer",
                    }}
                    onClick={() => remove(index)}
                  />
                </InputContainer>
              );
            })}
            <BtnContainer>
              <AddBtn
                onClick={() => {
                  append({});
                }}
              >
                추가하기 +
              </AddBtn>
            </BtnContainer>
          </Inner>
        </Container>
        <Btn onClick={onClick}>등록</Btn>
      </ModalBox>
    </ModalBg>
  );
}

export default ManagementModal;
