import instance from "API/MainAPI";

export default function GalleryGameCreationAPI(bodyFormData: FormData) {
  return instance.post("/v3/admins/games/managements", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}
