import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

interface MultipleSelectChipProps {
  checkOptions: string[];
  onCheckOptionsChange: (newOptions: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const options = [
  { value: "ROGUE_LITE_GAME", label: "로그라이트" },
  { value: "SHOOTING_GAME", label: "슈팅 게임" },
  { value: "RTS_GAME", label: "RTS(실시간 전략게임)" },
  { value: "AOS_GAME", label: "AOS" },
  { value: "MANAGEMENT_GAME", label: "경영(타이쿤)" },
  { value: "DEFENSE_GAME", label: "디펜스" },
  { value: "IDLE_TYPE_GAME", label: "방치형" },
  { value: "ARCADE_GAME", label: "아케이드" },
  { value: "PUZZLE_GAME", label: "퍼즐 게임" },
  { value: "TURN_BASE_GAME", label: "턴제" },
  { value: "SIMULATION_GAME", label: "시뮬레이션" },
  { value: "MERGE_GAME", label: "머지(Merge - 합성)" },
  { value: "ADVENTURE_GAME", label: "어드벤처 게임" },
  { value: "SANDBOX_GAME", label: "샌드박스" },
  { value: "RACING_GAME", label: "레이싱 게임" },
  { value: "STRATEGY_GAME", label: "전술" },
  { value: "ACTION_GAME", label: "액션 게임" },
  { value: "SPORTS_GAME", label: "스포츠 게임" },
  { value: "RPG_GAME", label: "RPG" },
  { value: "TRPG_GAME", label: "TRPG" },
  {
    value: "FARMING_GAME",
    label: "농사",
  },
  {
    value: "EVENT_GAME",
    label: "이벤트",
  },
];

export default function MultipleSelectChipGenre({
  checkOptions,
  onCheckOptionsChange,
}: MultipleSelectChipProps) {
  const handleChange = (event: SelectChangeEvent<typeof checkOptions>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === "string" ? value.split(",") : value;
    onCheckOptionsChange(newOptions);
  };

  return (
    <div>
      <FormControl sx={{ width: 504 }}>
        <InputLabel id="demo-multiple-chip-label">장르</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={checkOptions}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "0.5" }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    options.find((option) => option.value === value)?.label
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
