import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as PicturePreivewSvg } from "assets/images/PicturePreview.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { clearAll } from "components/common/clearStorage";
import { useMutation } from "react-query";
import GalleryShopCreationAPI from "./Data/GalleryShopCreationAPI";
import GalleryShopCreationKakaoBizAPI from "./Data/GalleryShopCreationKakaoBizAPI";
import { cardHeaderClasses } from "@mui/material";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 77px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.button`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 18px;
`;

const Inner = styled.div`
  display: flex;
  gap: 48px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 90px;
  margin-bottom: 30px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 513px;
  height: 60px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

const InputBox = styled.input`
  width: 471px;
  height: 60px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  outline: none;
`;

const TextArea = styled.textarea`
  width: 1074px;
  height: 280px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 20px;
  color: #262626;
  outline: none;
  margin-bottom: 40px;
  resize: none;
`;

const TextAreaSmall = styled.textarea`
  width: 471px;
  height: 180px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  padding: 20px;
  color: #262626;
  outline: none;
  resize: none;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 90px;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThumbnailLabel = styled.label`
  border: 3px dashed #e3e3e3;
  width: 406px;
  height: 406px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ThumbnailBtn = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f1f1f1;
  width: 90px;
  height: 39px;
  color: #5f5f5f;
  font-weight: 500;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbnailSpan = styled.span`
  color: #898989;
  font-size: 14px;
`;

const ShowThumb = styled.img`
  width: 406px;
  height: 406px;
  border-radius: 20px;
`;

export default function GalleryShopAdd() {
  const { register, watch, getValues, setValue } = useForm();
  const navigate = useNavigate();

  const formData = new FormData();
  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const watchCashierCategory = watch("cashierCategory");

  async function CallAPI() {
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const priceValue = getValues("price");
    const cashierCategoryValue = getValues("cashierCategory");
    const exchangeRewardCategoryValue = getValues("exchangeRewardCategory");
    const exchangeRewardsValue = getValues("exchangeRewards");
    const usageGuideValue = getValues("usageGuide");
    const howToUseValue = getValues("howToUse");
    const visibleValue = getValues("visible");
    const templateTokenValue = getValues("templateToken");

    if (watchCashierCategory === "KAKAO_BIZ") {
      formData.append("category", categoryValue);
      formData.append("templateToken", templateTokenValue);
      formData.append("description", descriptionValue);
      formData.append("eventHost", "GALLERY");
      formData.append("visible", visibleValue);

      await kakaoBizShopAddMutation.mutateAsync(formData);
    } else {
      const inputs = {
        category:
          categoryValue !== undefined ? categoryValue : "REWARD_EXCHANGE",
        title: titleValue,
        subtitle: subtitleValue,
        description: descriptionValue,
        price: priceValue,
        eventHost: "GALLERY",
        cashierCategory: cashierCategoryValue,
        exchangeRewardCategory:
          exchangeRewardCategoryValue !== undefined
            ? exchangeRewardCategoryValue
            : "NONE",
        exchangeRewards:
          exchangeRewardsValue !== undefined ? exchangeRewardsValue : 0,
        usageGuide: usageGuideValue !== undefined ? usageGuideValue : "",
        howToUse: howToUseValue !== undefined ? howToUseValue : "",
        visible: visibleValue,
      };

      const blob = new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      });

      formData.append("data", blob);

      if (thumbnail) {
        formData.append("image", thumbnail);
      }

      await shopAddMutation.mutateAsync(formData);
    }
  }

  const kakaoBizShopAddMutation = useMutation(GalleryShopCreationKakaoBizAPI, {
    onSuccess: () => {
      setModal(false);

      alert("기프티콘 등록이 완료되었습니다.");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  const shopAddMutation = useMutation(GalleryShopCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("기프티콘 등록이 완료되었습니다.");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const priceValue = getValues("price");
    const cashierCategoryValue = getValues("cashierCategory");
    const exchangeRewardCategoryValue = getValues("exchangeRewardCategory");
    const exchangeRewardsValue = getValues("exchangeRewards");
    const usageGuideValue = getValues("usageGuide");
    const howToUseValue = getValues("howToUse");
    const visibleValue = getValues("visible");
    const templateTokenValue = getValues("templateToken");

    if (cashierCategoryValue === "REWARD_EXCHANGE") {
      if (
        exchangeRewardCategoryValue &&
        cashierCategoryValue &&
        titleValue &&
        subtitleValue &&
        exchangeRewardsValue &&
        priceValue &&
        descriptionValue &&
        visibleValue
      ) {
        setModal(true);
      } else {
        alert("모든 항목을 입력하세요");
      }
    } else if (cashierCategoryValue === "KAKAO_BIZ") {
      if (
        categoryValue &&
        cashierCategoryValue &&
        templateTokenValue &&
        descriptionValue &&
        visibleValue
      ) {
        setModal(true);
      } else {
        alert("모든 항목을 입력하세요");
      }
    } else if (cashierCategoryValue === "CODE") {
      if (
        categoryValue &&
        cashierCategoryValue &&
        titleValue &&
        subtitleValue &&
        priceValue &&
        usageGuideValue &&
        howToUseValue &&
        descriptionValue &&
        visibleValue
      ) {
        setModal(true);
      } else {
        alert("모든 항목을 입력하세요");
      }
    } else if (cashierCategoryValue === "MANUAL") {
      if (
        categoryValue &&
        cashierCategoryValue &&
        titleValue &&
        subtitleValue &&
        priceValue &&
        descriptionValue &&
        visibleValue
      ) {
        setModal(true);
      } else {
        alert("모든 항목을 입력하세요");
      }
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  const [thumbnail, setThumbnail] = useState<File | undefined>();

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("bannerUrl", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <HeaderContainer>
        <Title>상점 등록</Title>
        <Button onClick={onAdd}>등록하기</Button>
      </HeaderContainer>
      <Inner>
        <Content>
          <ContentContainer>
            <LabelContainer>
              <SubTitle>구매 방식</SubTitle>
              <SelectBoxContainer>
                <SelectBox
                  defaultValue={"placeholder"}
                  {...register("cashierCategory")}
                >
                  <option value={"placeholder"} disabled hidden selected>
                    기프티콘 구매 방식을 선택하세요.
                  </option>
                  <option value={"REWARD_EXCHANGE"}>재화 교환 (포인트)</option>
                  <option value={"KAKAO_BIZ"}>카카오 비즈</option>
                  <option value={"CODE"}>등록된 기프티콘 코드 발송</option>
                  <option value={"MANUAL"}>관리자 직접 발송</option>
                </SelectBox>
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{
                    rotate: "90deg",
                    color: "#2282e9",
                    position: "absolute",
                    top: "22px",
                    right: "17px",
                  }}
                />
              </SelectBoxContainer>
            </LabelContainer>
            {(watchCashierCategory === "KAKAO_BIZ" ||
              watchCashierCategory === "CODE" ||
              watchCashierCategory === "MANUAL") && (
              <LabelContainer>
                <SubTitle>카테고리</SubTitle>
                <SelectBoxContainer>
                  <SelectBox
                    defaultValue={"placeholder"}
                    {...register("category")}
                  >
                    <option value={"placeholder"} disabled hidden selected>
                      상품의 카테고리를 선택하세요.
                    </option>
                    <option value={"MOBILE_COUPON"}>
                      모바일 상품 교환 쿠폰
                    </option>
                    <option value={"GOOGLE_PLAY"}>
                      구글플레이 기프트 쿠폰
                    </option>
                    <option value={"GAME_GIFT_CARD"}>게임 기프트 카드</option>
                    <option value={"CAFE_BAKERY"}>카페, 베이커리</option>
                    <option value={"CONVENIENCE_STORE"}>편의점</option>
                    <option value={"CHICKEN_PIZZA_BURGER"}>
                      치킨, 피자, 버거
                    </option>
                    <option value={"EAT_OUT"}>외식</option>
                    <option value={"DONATION"}>후원</option>
                    <option value={"REWARD_EXCHANGE"}>재화 교환</option>
                  </SelectBox>
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{
                      rotate: "90deg",
                      color: "#2282e9",
                      position: "absolute",
                      top: "22px",
                      right: "17px",
                    }}
                  />
                </SelectBoxContainer>
              </LabelContainer>
            )}
            {watchCashierCategory === "REWARD_EXCHANGE" && (
              <LabelContainer>
                <SubTitle>카테고리</SubTitle>
                <SelectBoxContainer>
                  <SelectBox
                    defaultValue={"placeholder"}
                    {...register("exchangeRewardCategory")}
                  >
                    <option value={"placeholder"} disabled hidden selected>
                      재화를 선택하세요.
                    </option>
                    <option value={"POINT"}>포인트</option>
                  </SelectBox>
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{
                      rotate: "90deg",
                      color: "#2282e9",
                      position: "absolute",
                      top: "22px",
                      right: "17px",
                    }}
                  />
                </SelectBoxContainer>
              </LabelContainer>
            )}
          </ContentContainer>
          {watchCashierCategory === "REWARD_EXCHANGE" && (
            <>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>상품명</SubTitle>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </LabelContainer>
                <LabelContainer>
                  <SubTitle>상품 부제목</SubTitle>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </LabelContainer>
              </ContentContainer>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>적립될 포인트</SubTitle>
                  <InputBox
                    type="number"
                    {...register("exchangeRewards")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </LabelContainer>
                <LabelContainer>
                  <SubTitle>가격 (보석수)</SubTitle>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </LabelContainer>
              </ContentContainer>
              <LabelContainer>
                <SubTitle>상세 설명</SubTitle>
                <TextArea {...register("description")}></TextArea>
              </LabelContainer>
              <LabelContainer>
                <SubTitle>어플표시</SubTitle>
                <RadioContainer>
                  <div>
                    <input type="radio" {...register("visible")} value="true" />
                    <label>보이기</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...register("visible")}
                      value="false"
                    />
                    <label>숨기기</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </>
          )}
          {watchCashierCategory === "KAKAO_BIZ" && (
            <>
              <LabelContainer>
                <SubTitle>템플릿 토큰</SubTitle>
                <InputBox
                  type="text"
                  {...register("templateToken")}
                  style={{ width: "1074px", marginBottom: "30px" }}
                />
              </LabelContainer>
              <LabelContainer>
                <SubTitle>상세 설명</SubTitle>
                <TextArea {...register("description")}></TextArea>
              </LabelContainer>
              <LabelContainer>
                <SubTitle>어플표시</SubTitle>
                <RadioContainer>
                  <div>
                    <input type="radio" {...register("visible")} value="true" />
                    <label>보이기</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...register("visible")}
                      value="false"
                    />
                    <label>숨기기</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </>
          )}
          {watchCashierCategory === "CODE" && (
            <>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>상품명</SubTitle>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </LabelContainer>
                <LabelContainer>
                  <SubTitle>상품 부제목</SubTitle>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </LabelContainer>
              </ContentContainer>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>가격 (보석수)</SubTitle>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </LabelContainer>
              </ContentContainer>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>이용안내</SubTitle>
                  <TextAreaSmall
                    {...register("usageGuide")}
                    placeholder={`카카오 알림톡으로 발송될 메세지 입니다. 
오피스콘 이용안내에 나와있는 부분으로 작성해주세요.

[ex]
*상품권은 회원 로그인 후 사용 가능하며, 최초 1회 본인인증 절차 진행 후 사용 가능합니다.(자금세탁방지법 의무사항)`}
                  ></TextAreaSmall>
                </LabelContainer>
                <LabelContainer>
                  <SubTitle>사용방법</SubTitle>
                  <TextAreaSmall
                    {...register("howToUse")}
                    placeholder={`카카오 알림톡으로 발송될 메세지 입니다. 
오피스콘 이용안내에 나와있는 부분으로 작성해주세요.

[ex]
*배달의민족 앱 → my배민 → 받은 선물 → [선물코드 등록] 에서 등록 후 사용할 수 있습니다.`}
                  ></TextAreaSmall>
                </LabelContainer>
              </ContentContainer>
              <LabelContainer>
                <SubTitle>상세 설명</SubTitle>
                <TextArea {...register("description")}></TextArea>
              </LabelContainer>
              <LabelContainer>
                <SubTitle>어플표시</SubTitle>
                <RadioContainer>
                  <div>
                    <input type="radio" {...register("visible")} value="true" />
                    <label>보이기</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...register("visible")}
                      value="false"
                    />
                    <label>숨기기</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </>
          )}
          {watchCashierCategory === "MANUAL" && (
            <>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>상품명</SubTitle>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </LabelContainer>
                <LabelContainer>
                  <SubTitle>상품 부제목</SubTitle>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </LabelContainer>
              </ContentContainer>
              <ContentContainer>
                <LabelContainer>
                  <SubTitle>가격 (보석수)</SubTitle>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </LabelContainer>
              </ContentContainer>
              <LabelContainer>
                <SubTitle>상세 설명</SubTitle>
                <TextArea {...register("description")}></TextArea>
              </LabelContainer>
              <LabelContainer>
                <SubTitle>어플표시</SubTitle>
                <RadioContainer>
                  <div>
                    <input type="radio" {...register("visible")} value="true" />
                    <label>보이기</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...register("visible")}
                      value="false"
                    />
                    <label>숨기기</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </>
          )}
        </Content>
        <ThumbnailContainer>
          {(watchCashierCategory === "REWARD_EXCHANGE" ||
            watchCashierCategory === "CODE" ||
            watchCashierCategory === "MANUAL") && (
            <>
              <SubTitle>썸네일</SubTitle>
              <ThumbnailLabel htmlFor="thumbnail">
                {thumbnail ? (
                  <div>
                    <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      id="thumbnail"
                      style={{ display: "none" }}
                      {...register("bannerUrl")}
                      onChange={Thumbnail}
                    />
                    <PicturePreivewSvg style={{ color: "#c0c0c0" }} />
                    <ThumbnailBtn>파일 선택</ThumbnailBtn>
                    <ThumbnailSpan>*선택된 파일 없음</ThumbnailSpan>
                  </>
                )}
              </ThumbnailLabel>
            </>
          )}
        </ThumbnailContainer>
      </Inner>
    </Wrapper>
  );
}
