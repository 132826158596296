//abc 순으로 정렬

export function clearAll() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");

  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");
  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearAds() {
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");
  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearApps() {
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");
  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearCheering() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");
  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearCoupon() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearDailyQuest() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");

  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearElection() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
}

export function clearGameNews() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function cleargifticon() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearInAppGame() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");

  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearInvitStats() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearNotice() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");

  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearNotification() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearOrder() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearPlistarOrder() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearReview() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearReviewAwards() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");

  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearSupporter() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearSurvey() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearBounceRateSurvey() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearSurveyList() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearTestOne() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearTestTwo() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("testPage1");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearTestGame() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearTestUser() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearUsage() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearUser() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearUserRegister() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}

export function clearUserStats() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("orderPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("electionPage");
}

export function clearGalleryOrder() {
  sessionStorage.removeItem("adsPage");
  sessionStorage.removeItem("appStatsPage");
  sessionStorage.removeItem("cheeringPage");
  sessionStorage.removeItem("couponPage");
  sessionStorage.removeItem("dailyQuestPage");
  sessionStorage.removeItem("gameNewsPage");
  sessionStorage.removeItem("gifticonPage");
  sessionStorage.removeItem("inAppGamePage");
  sessionStorage.removeItem("invitStatsPage");
  sessionStorage.removeItem("noticePage");
  sessionStorage.removeItem("notificationPage");
  sessionStorage.removeItem("plistarOrderPage");
  sessionStorage.removeItem("reviewPage");
  sessionStorage.removeItem("reviewAwardsPage");
  sessionStorage.removeItem("supporterPage");
  sessionStorage.removeItem("suveyPage");
  sessionStorage.removeItem("suveyListPage");
  sessionStorage.removeItem("testPage1");
  sessionStorage.removeItem("testPage2");

  sessionStorage.removeItem("testGamePage");
  sessionStorage.removeItem("testUserPage");
  sessionStorage.removeItem("usagePage");
  sessionStorage.removeItem("userPage");
  sessionStorage.removeItem("userRegisterPage");
  sessionStorage.removeItem("userStatsPage");
  sessionStorage.removeItem("electionPage");
}
