import instance from "API/MainAPI";

export default function GalleryGameModification(
  id: number,
  formData: FormData
) {
  return instance.post(`/v3/admins/games/managements/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
