import instance from "API/MainAPI";

export default function GalleryManagementListAPI(id: any, page: number) {
  return instance.get(`/v3/admins/gifticons/${id}/codes`, {
    params: {
      pageSize: 10,
      pageNumber: page - 1,
    },
  });
}
