import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearAll } from "components/common/clearStorage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import GalleryBannerCreationAPI from "./Data/GalleryBannerCreationAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  background-color: #2282e9;
  color: #fff;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const Img = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 22px;
`;
const Comment = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #262626;
`;

const Input = styled.input`
  width: 470px;
  height: 60px;
  border: 2px solid #2282e9;
  border-radius: 6px;
  padding: 0px 20px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 513px;
  height: 60px;
  border-radius: 6px;
  border: 2px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

export default function GalleryBannerAdd() {
  const { register, getValues, setValue, watch } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const [img, setImg] = useState<File | undefined>();
  const formData = new FormData();

  const showLink = watch("redirectionCategory");

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI() {
    const titleValue = getValues("title");
    const descriptionValue = "";
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");
    const visibleValue = getValues("visible");
    const redirectionCategoryValue = getValues("redirectionCategory");

    const blob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          description: descriptionValue,
          redirectLink: redirectLinkValue,
          category: categoryValue,
          visible: visibleValue,
          redirectionCategory: redirectionCategoryValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);
    if (img) {
      formData.append("photo", img);
    }

    await adsAddMutation.mutateAsync(formData);
  }

  const adsAddMutation = useMutation(GalleryBannerCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("배너 등록이 완료되었습니다.");
      queryClient.invalidateQueries("galleryNotices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const visibleValue = getValues("visible");
    const categoryValue = getValues("category");
    const redirectionCategoryValue = getValues("redirectionCategory");
    const redirectLinkValue = getValues("redirectLink");

    const allFieldsFilled =
      img && categoryValue && visibleValue && redirectionCategoryValue;

    if (showLink === "EXTERNALS") {
      // 외부 링크이며 링크가 없는 경우
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else if (allFieldsFilled) {
        setModal(true);
      }
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setModal(true);
    } else {
      alert("이미지, 카테고리, 연결링크, 어플 표시 여부는 필수입니다.");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <HeaderContainer>
        <Title>배너 등록</Title>
        <Button onClick={onAdd}>등록하기</Button>
      </HeaderContainer>
      <ContentContainer>
        <div>
          <div style={{ width: "500px" }}>
            {img && <Img src={URL.createObjectURL(img)} alt="" />}
          </div>
          <input type="file" accept=".jpg, .png" onChange={Image} />
        </div>
        <InfoContainer>
          <LabelContainer>
            <div style={{ display: "flex", gap: "20px" }}>
              <Label>배너 이름</Label>
              <Comment>*필수 아님</Comment>
            </div>
            <Input type="text" {...register("title")} />
          </LabelContainer>
          <LabelContainer>
            <Label>카테고리</Label>
            <SelectBoxContainer>
              <SelectBox {...register("category")}>
                <option value={"GALLERY_BANNER"}>
                  온라인 전시관 메인 페이지 배너
                </option>
                <option value={"GALLERY_BANNER_WEB"}>
                  온라인 전시관 메인 페이지 배너(web)
                </option>
                <option value={"GALLERY_POINT_SHOP_BANNER"}>
                  온라인 전시관 포인트샵 배너
                </option>
                <option value={"GALLERY_POINT_SHOP_BANNER_WEB"}>
                  온라인 전시관 포인트샵 배너(web)
                </option>
              </SelectBox>
              <FontAwesomeIcon
                icon={faPlay}
                style={{
                  rotate: "90deg",
                  color: "#2282e9",
                  position: "absolute",
                  top: "22px",
                  right: "17px",
                }}
              />
            </SelectBoxContainer>
          </LabelContainer>
          <LabelContainer>
            <Label>연결 링크 카테고리</Label>
            <SelectBoxContainer>
              <SelectBox {...register("redirectionCategory")}>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="DAY_MISSION">데이 미션</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </SelectBox>
              <FontAwesomeIcon
                icon={faPlay}
                style={{
                  rotate: "90deg",
                  color: "#2282e9",
                  position: "absolute",
                  top: "22px",
                  right: "17px",
                }}
              />
            </SelectBoxContainer>
          </LabelContainer>
          {showLink == "EXTERNALS" && (
            <LabelContainer>
              <div style={{ display: "flex", gap: "20px" }}>
                <Label>연결 링크</Label>
                <Comment>*필수 아님</Comment>
              </div>
              <Input type="text" {...register("redirectLink")} />
            </LabelContainer>
          )}
          <LabelContainer>
            <Label>어플 표시</Label>
            <div style={{ display: "flex", gap: "96px" }}>
              <div>
                <input type="radio" value="true" {...register("visible")} />
                <label>보이기</label>
              </div>
              <div>
                <input type="radio" value="false" {...register("visible")} />
                <label>숨기기</label>
              </div>
            </div>
          </LabelContainer>
        </InfoContainer>
      </ContentContainer>
    </Wrapper>
  );
}
