import instance from "API/MainAPI";

export default function GallerySurveyCreationAPI(formData: any) {
  return instance.post("/v3/admins/surveys/managements/questions", formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
