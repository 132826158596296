import { useQuery } from "react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useEffect } from "react";
import { clearGalleryOrder } from "components/common/clearStorage";
import GalleryShopListAPI from "./Data/GalleryShopListAPI";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
`;

const Table = styled.div`
  width: 100%;
  text-align: center;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  background-color: #333742;
  color: #fff;
  height: 54px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const HeaderTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`;

const TBodyTr = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  border: 2px solid #f1f1f1;
  height: 64px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const Td = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15;
`;

const DetailButton = styled.button`
  width: 126px;
  height: 43px;
  background-color: #f0f9ff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  border: none;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

export default function GalleryShopList() {
  const pageNum = sessionStorage.getItem("galleryShopPage") || "1";

  const { data: shopData, refetch } = useQuery(
    ["galleryOrder", pageNum],
    () => GalleryShopListAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("galleryShopPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearGalleryOrder();
  }, []);

  let count = 0;
  return (
    <Wrapper>
      <HeaderContainer>
        <Title>상점 목록</Title>
        <Link to={"/gallery/shop/add"} style={{ textDecoration: "none" }}>
          <Button>등록</Button>
        </Link>
      </HeaderContainer>
      <Table>
        <THead>
          <HeaderTh>No.</HeaderTh>
          <HeaderTh>브랜드</HeaderTh>
          <HeaderTh>제품명</HeaderTh>
          <HeaderTh>분류</HeaderTh>
          <HeaderTh>보석</HeaderTh>
          <HeaderTh>공개여부</HeaderTh>
          <HeaderTh>재고</HeaderTh>
          <HeaderTh />
        </THead>
        {shopData?.data.page.content &&
          shopData?.data.page.content.map((shop: any) => {
            count = count + 1;
            return (
              <TBodyTr key={shop.id}>
                <Td>
                  {shopData?.data?.page?.pageable.totalElements -
                    shopData?.data?.page?.pageable.pageNumber *
                      shopData?.data?.page?.pageable.pageSize -
                    count +
                    1}
                </Td>
                <Td>{shop.title}</Td>
                <Td>{shop.subtitle}</Td>
                <Td>
                  {shop.category === "MOBILE_COUPON"
                    ? "모바일 상품 교환 쿠폰"
                    : shop.category === "GOOGLE_PLAY"
                    ? "구글플레이 기프트 쿠폰"
                    : shop.category === "EXP"
                    ? "경험치"
                    : shop.category === "GAME_GIFT_CARD"
                    ? "게임 기프트 카드"
                    : shop.category === "CAFE_BAKERY"
                    ? "카페,베이커리"
                    : shop.category === "CONVENIENCE_STORE"
                    ? "편의점"
                    : shop.category === "CHICKEN_PIZZA_BURGER"
                    ? "치킨,피자,버거"
                    : shop.category === "EAT_OUT"
                    ? "외식"
                    : shop.category === "DONATION"
                    ? "후원"
                    : shop.category === "REWARD_EXCHANGE"
                    ? "재화 교환 방식"
                    : "코드 오류"}
                </Td>
                <Td>{shop.price}</Td>
                <Td>{shop.visible ? "보임" : "숨김"}</Td>
                <Td>{shop.stock}개</Td>
                <Td>
                  <Link
                    to={"/gallery/shop/" + shop.id}
                    className="link"
                    state={{
                      id: shop.id,
                      cashierCategory: shop.cashierCategory,
                    }}
                  >
                    <DetailButton>자세히</DetailButton>
                  </Link>
                </Td>
              </TBodyTr>
            );
          })}
      </Table>
      <PaginationWrapper>
        <Pagination
          activePage={Number(pageNum)}
          itemsCountPerPage={10}
          totalItemsCount={shopData?.data?.page?.pageable.totalElements || 1}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
