import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import GalleryShopDetailAPI from "./Data/GalleryShopDetailAPI";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { clearAll } from "components/common/clearStorage";
import GalleryShopModificationKakaoBizAPI from "./Data/GalleryShopModificationKakaoBizAPI";
import GalleryShopModificationAPI from "./Data/GalleryShopModificationAPI";
import EditModal from "./Modal/EditModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlay,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import GalleryShopExcelDownloadAPI from "./Data/GalleryShopExcelDownloadAPI";
import ManagementModal from "./Modal/ManagementModal";
import GalleryManagementAPI from "./Data/GalleryManagementAPI";
import GalleryManagementListAPI from "./Data/GalleryManagementListAPI";
import Pagination from "react-js-pagination";
import DeleteModal from "./Modal/DeleteModal";
import GalleryManagementDeleteAPI from "./Data/GalleryManagementDeleteAPI";
import GalleryManagementExcelAPI from "./Data/GalleryManagementExcelAPI";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 18px;
`;

const Button = styled.div`
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
`;

const EditBtn = styled(Button)`
  background-color: #2282e9;
  color: #fff;
`;

const DownloadBtn = styled(Button)`
  background-color: #fff;
  color: #2282e9;
  border: 2px solid #2282e9;
`;

const Inner = styled.div`
  display: flex;
  gap: 50px;
`;

const Info = styled.div`
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 20px;
  width: 715px;
`;
const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #262626;
  margin-bottom: 16px;
`;

const Img = styled.img`
  width: 305px;
  height: 305px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  width: 43px;
`;

const Element = styled.div`
  background-color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  color: #555555;
  border-radius: 10px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  height: 49px;
  width: 255px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  padding: 20px;
  border-radius: 10px;
  width: 643px;
  height: 192px;
`;

const Edit = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditLabel = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #262626;
  margin-bottom: 12px;
`;

const InputBox = styled.input`
  width: 352px;
  height: 60px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  outline: none;
`;

const RadioLabel = styled.label``;

const ContentContainer = styled.div`
  display: flex;
  gap: 22px;
  margin-bottom: 30px;
`;

const EditLabelContainer = styled.div``;

const EditTextArea = styled.textarea`
  width: 777px;
  height: 280px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 20px;
  color: #262626;
  outline: none;
  margin-bottom: 40px;
  resize: none;
`;

const SelectBox = styled.select`
  width: 394px;
  height: 60px;
  border-radius: 6px;
  border: 1.5px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

const SelectBoxContainer = styled.div`
  position: relative;
  display: flex;
  width: 394px;
`;

const ManagementBox = styled.div`
  width: 1570px;
  height: 123px;
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555555;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
`;

const ManagementContainer = styled.div`
  width: 1550px;
  padding: 52px 60px;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
`;

const Management = styled.div`
  margin-top: 30px;
`;

const ManagementSpan = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #262626;
  margin-bottom: 30px;
`;

const ManagementBtnContainer = styled.div`
  display: flex;
  gap: 36px;
  margin-bottom: 36px;
`;

const ManagementBtn = styled.div`
  background-color: #c9e8fe;
  width: 679px;
  height: 72px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555555;
  font-weight: 600;
  cursor: pointer;
`;

const ManagementRow = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 16px;
`;

const ManagementCode = styled.div`
  background-color: #f8f8f8;
  width: 847px;
  height: 65px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
`;

const ManagementDate = styled.div`
  background-color: #f8f8f8;
  width: 525px;
  height: 65px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

export default function GalleryShopDetail() {
  const pageNum = sessionStorage.getItem("galleryShopDetailPage") || "1";
  const { register, setValue, getValues, watch } = useForm();
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const id = location.state.id;
  const cashierCategory = location.state.cashierCategory;

  const formData = new FormData();

  const { data: shopDetail, isSuccess } = useQuery(["shopDetail", id], () =>
    GalleryShopDetailAPI(id)
  );

  const { data: codes, refetch } = useQuery(["codes", id], () =>
    GalleryManagementListAPI(id, Number(pageNum))
  );

  const [modalEdit, setModalEdit] = useState(false);
  const [modalManagement, setModalManagement] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  function onEditModal() {
    setModalEdit(true);
  }
  function closeEdit() {
    setModalEdit(false);
  }
  function onManagementModal() {
    setModalManagement(true);
  }
  function closeManagement() {
    setModalManagement(false);
  }

  async function CallEditAPI() {
    if (!isSuccess) {
      return;
    }
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const priceValue = getValues("price");
    const cashierCategoryValue = getValues("cashierCategory");
    const exchangeRewardCategoryValue = getValues("exchangeRewardCategory");
    const exchangeRewardsValue = getValues("exchangeRewards");
    const usageGuideValue = getValues("usageGuide");
    const howToUseValue = getValues("howToUse");
    const visibleValue = getValues("visible");
    const templateTokenValue = getValues("templateToken");

    if (cashierCategory === "KAKAO_BIZ") {
      formData.append("category", categoryValue);
      formData.append("templateToken", templateTokenValue);
      formData.append("description", descriptionValue);
      formData.append("eventHost", "GALLERY");
      formData.append("visible", visibleValue);

      await kakaoBizShopEdit.mutateAsync(formData);
    } else {
      const inputs = {
        category:
          categoryValue !== undefined ? categoryValue : "REWARD_EXCHANGE",
        title: titleValue,
        subtitle: subtitleValue,
        description: descriptionValue,
        price: priceValue,
        eventHost: "GALLERY",
        cashierCategory: cashierCategoryValue,
        exchangeRewardCategory:
          exchangeRewardCategoryValue !== undefined
            ? exchangeRewardCategoryValue
            : "NONE",
        exchangeRewards:
          exchangeRewardsValue !== undefined ? exchangeRewardsValue : 0,
        usageGuide: usageGuideValue !== undefined ? usageGuideValue : "",
        howToUse: howToUseValue !== undefined ? howToUseValue : "",
        visible: visibleValue,
      };

      const blob = new Blob([JSON.stringify(inputs)], {
        type: "application/json",
      });

      formData.append("data", blob);

      formData.append("image", shopDetail?.data.bannerUrl);

      await shopEdit.mutateAsync(formData);
    }
  }

  const kakaoBizShopEdit = useMutation(
    (formData: FormData) => GalleryShopModificationKakaoBizAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("수정이 완료되었습니다.");
        queryClient.invalidateQueries("shopDetailKakaoBiz");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error" + error);

        closeEdit();
      },
    }
  );

  const shopEdit = useMutation(
    (formData: FormData) => GalleryShopModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("수정이 완료되었습니다.");
        queryClient.invalidateQueries("shopDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error" + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await GalleryShopDetailAPI(id);

      if (response) {
        setValue("templateToken", response.data.templateToken);
        setValue("title", response.data.title);
        setValue("subtitle", response.data.subtitle);
        setValue("price", response.data.price);
        setValue("exchangeRewards", response.data.exchangeRewards);
        setValue(
          "exchangeRewardCategory",
          response.data.exchangeRewardCategory
        );
        setValue("usageGuide", response.data.usageGuide);
        setValue("howToUse", response.data.howToUse);
        setValue("description", response.data.description);
        setValue("category", response.data.category);
        setValue("visible", response.data.visible);
        setValue("cashierCategory", response.data.cashierCategory);
      }
    };

    fetchData();
  }, []);

  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  function onEdit() {
    onEditModal();
  }

  const DownloadExcel = useMutation(() => GalleryShopExcelDownloadAPI(), {
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "excel.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    },
    onError: (error) => {
      console.error("파일 다운로드 실패:", error);
    },
  });

  const clickDownloadBtn = async () => {
    await DownloadExcel.mutateAsync();
  };

  async function CallManagementAPI(formData: any) {
    await ManagementMutation.mutateAsync(formData);
  }

  const ManagementMutation = useMutation(
    (formData: FormData) => GalleryManagementAPI(id, formData),
    {
      onSuccess: () => {
        closeManagement();

        alert("등록이 완료되었습니다.");
        queryClient.invalidateQueries("shopDetailManagement");
      },
      onError: (error) => {
        alert("Error" + error);

        closeManagement();
      },
    }
  );

  const [openManagement, setOpenManagement] = useState(false);

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("galleryShopDetailPage", pageNumber.toString());
    refetch();
  };

  const [deleteId, setDeleteId] = useState();

  function onDeleteModal(id: any) {
    setModalDelete(true);
    setDeleteId(id);
  }
  function closeDelete() {
    setModalDelete(false);
  }

  async function CallDeleteAPI(codeId: any) {
    await DeleteMutation.mutateAsync(codeId);
  }

  const DeleteMutation = useMutation(
    (codeId: any) => GalleryManagementDeleteAPI(id, codeId),
    {
      onSuccess: () => {
        closeDelete();

        alert("삭제가 완료되었습니다.");
        queryClient.invalidateQueries("galleryManagementDelete");
      },
      onError: (error) => {
        alert("Error" + error);

        closeDelete();
      },
    }
  );

  const [excelFile, setExcelFile] = useState<any>(null);

  const UploadMutation = useMutation(
    (formData: any) => GalleryManagementExcelAPI(id, formData),
    {
      onSuccess: (data) => {
        console.log(data);
        onManagementModal();
        setExcelFile(data);
      },
      onError: (error) => {
        alert("Error" + error);
      },
    }
  );

  const UploadExcel = async (file: any) => {
    const formData = new FormData();
    formData.append("excel", file);
    await UploadMutation.mutateAsync(formData);
  };

  const handleFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      UploadExcel(event.target.files[0]);
    }
  };

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      {modalManagement && (
        <ManagementModal
          close={closeManagement}
          CallAPI={CallManagementAPI}
          excel={excelFile}
        />
      )}
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={CallDeleteAPI}
          deleteId={deleteId}
        />
      )}
      <HeaderContainer>
        <Title>상점 목록</Title>
        <ButtonContainer>
          {cashierCategory === "CODE" && (
            <DownloadBtn onClick={clickDownloadBtn}>엑셀 양식 다운</DownloadBtn>
          )}
          <EditBtn onClick={onEdit}>수정하기</EditBtn>
        </ButtonContainer>
      </HeaderContainer>
      <Inner>
        <Info>
          <SubTitle>상품정보</SubTitle>
          <div style={{ display: "flex", gap: "30px" }}>
            <Img src={shopDetail?.data.bannerUrl} alt="예시 이미지" />
            <InfoContainer>
              <LabelContainer>
                <Label>제품명</Label>
                <Element>{shopDetail?.data.subtitle}</Element>
              </LabelContainer>
              <LabelContainer>
                <Label>브랜드</Label>
                <Element>{shopDetail?.data.title}</Element>
              </LabelContainer>
              <LabelContainer>
                <Label>분류</Label>
                <Element>
                  {shopDetail?.data.category === "MOBILE_COUPON"
                    ? "모바일 상품 교환 쿠폰"
                    : shopDetail?.data.category === "GOOGLE_PLAY"
                    ? "구글플레이 기프트 쿠폰"
                    : shopDetail?.data.category === "GAME_GIFT_CARD"
                    ? "게임 기프트 카드"
                    : shopDetail?.data.category === "CAFE_BAKERY"
                    ? "카페|베이커리"
                    : shopDetail?.data.category === "CONVENIENCE_STORE"
                    ? "편의점"
                    : shopDetail?.data.category === "CHICKEN_PIZZA_BURGER"
                    ? "치킨|피자|버거"
                    : shopDetail?.data.category === "EAT_OUT"
                    ? "외식"
                    : shopDetail?.data.category === "DONATION"
                    ? "후원"
                    : shopDetail?.data.category === "REWARD_EXCHANGE"
                    ? "재화 교환"
                    : "기타"}
                </Element>
              </LabelContainer>
              <LabelContainer>
                <Label>보석</Label>
                <Element>{shopDetail?.data.price}</Element>
              </LabelContainer>
            </InfoContainer>
          </div>

          {cashierCategory === "CODE" && (
            <>
              <SubTitle style={{ marginTop: "40px" }}>이용 안내</SubTitle>
              <Description style={{ height: "140px" }}>
                {shopDetail?.data.usageGuide}
              </Description>
              <SubTitle style={{ marginTop: "40px" }}>사용 방법</SubTitle>
              <Description style={{ height: "140px" }}>
                {shopDetail?.data.howToUse}
              </Description>
            </>
          )}
          <SubTitle style={{ marginTop: "40px" }}>상세 설명</SubTitle>
          <Description style={{ height: "140px" }}>
            {shopDetail?.data.description}
          </Description>
        </Info>
        <Edit>
          <div style={{ display: "flex", gap: "40px", marginBottom: "45px" }}>
            <EditLabel style={{ marginBottom: "0px" }}>어플 표시</EditLabel>
            <RadioLabel>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              보이기
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              숨기기
            </RadioLabel>
          </div>
          {cashierCategory === "REWARD_EXCHANGE" && (
            <>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>상품명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>브랜드명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>가격 (보석수)</EditLabel>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>적립될 포인트</EditLabel>
                  <InputBox
                    type="number"
                    {...register("exchangeRewards")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <EditLabelContainer>
                <EditLabel>상세 설명</EditLabel>
                <EditTextArea {...register("description")}></EditTextArea>
              </EditLabelContainer>
            </>
          )}
          {cashierCategory === "KAKAO_BIZ" && (
            <>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>템플릿 토큰</EditLabel>
                  <InputBox
                    type="text"
                    {...register("templateToken")}
                    placeholder="제품명을 작성해주세요."
                    style={{ width: "777px" }}
                  />
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>카테고리</EditLabel>
                  <SelectBoxContainer>
                    <SelectBox {...register("category")}>
                      <option value={"MOBILE_COUPON"}>
                        모바일 상품 교환 쿠폰
                      </option>
                      <option value={"GOOGLE_PLAY"}>
                        구글플레이 기프트 쿠폰
                      </option>
                      <option value={"GAME_GIFT_CARD"}>게임 기프트 카드</option>
                      <option value={"CAFE_BAKERY"}>카페, 베이커리</option>
                      <option value={"CONVENIENCE_STORE"}>편의점</option>
                      <option value={"CHICKEN_PIZZA_BURGER"}>
                        치킨, 피자, 버거
                      </option>
                      <option value={"EAT_OUT"}>외식</option>
                      <option value={"DONATION"}>후원</option>
                      <option value={"REWARD_EXCHANGE"}>재화 교환</option>
                    </SelectBox>
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{
                        rotate: "90deg",
                        color: "#2282e9",
                        position: "absolute",
                        top: "22px",
                        right: "17px",
                      }}
                    />
                  </SelectBoxContainer>
                </EditLabelContainer>
              </ContentContainer>
              <EditLabelContainer>
                <EditLabel>상세 설명</EditLabel>
                <EditTextArea {...register("description")}></EditTextArea>
              </EditLabelContainer>
            </>
          )}
          {cashierCategory === "CODE" && (
            <>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>상품명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>브랜드명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>카테고리</EditLabel>
                  <SelectBoxContainer>
                    <SelectBox {...register("category")}>
                      <option value={"MOBILE_COUPON"}>
                        모바일 상품 교환 쿠폰
                      </option>
                      <option value={"GOOGLE_PLAY"}>
                        구글플레이 기프트 쿠폰
                      </option>
                      <option value={"GAME_GIFT_CARD"}>게임 기프트 카드</option>
                      <option value={"CAFE_BAKERY"}>카페, 베이커리</option>
                      <option value={"CONVENIENCE_STORE"}>편의점</option>
                      <option value={"CHICKEN_PIZZA_BURGER"}>
                        치킨, 피자, 버거
                      </option>
                      <option value={"EAT_OUT"}>외식</option>
                      <option value={"DONATION"}>후원</option>
                      <option value={"REWARD_EXCHANGE"}>재화 교환</option>
                    </SelectBox>
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{
                        rotate: "90deg",
                        color: "#2282e9",
                        position: "absolute",
                        top: "22px",
                        right: "17px",
                      }}
                    />
                  </SelectBoxContainer>
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>차감 보석 수</EditLabel>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>이용 안내</EditLabel>
                  <EditTextArea
                    {...register("usageGuide")}
                    style={{ width: "354px", height: "189px" }}
                  ></EditTextArea>
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>사용 방법</EditLabel>
                  <EditTextArea
                    {...register("howToUse")}
                    style={{ width: "354px", height: "189px" }}
                  ></EditTextArea>
                </EditLabelContainer>
              </ContentContainer>
              <EditLabelContainer>
                <EditLabel>상세 설명</EditLabel>
                <EditTextArea
                  {...register("description")}
                  style={{ height: "204px" }}
                ></EditTextArea>
              </EditLabelContainer>
            </>
          )}
          {cashierCategory === "MANUAL" && (
            <>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>상품명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("subtitle")}
                    placeholder="제품명을 작성해주세요."
                  />
                </EditLabelContainer>
                <EditLabelContainer>
                  <EditLabel>브랜드명</EditLabel>
                  <InputBox
                    type="text"
                    {...register("title")}
                    placeholder="브랜드명을 작성해주세요."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>카테고리</EditLabel>
                  <SelectBoxContainer>
                    <SelectBox {...register("category")}>
                      <option value={"MOBILE_COUPON"}>
                        모바일 상품 교환 쿠폰
                      </option>
                      <option value={"GOOGLE_PLAY"}>
                        구글플레이 기프트 쿠폰
                      </option>
                      <option value={"GAME_GIFT_CARD"}>게임 기프트 카드</option>
                      <option value={"CAFE_BAKERY"}>카페, 베이커리</option>
                      <option value={"CONVENIENCE_STORE"}>편의점</option>
                      <option value={"CHICKEN_PIZZA_BURGER"}>
                        치킨, 피자, 버거
                      </option>
                      <option value={"EAT_OUT"}>외식</option>
                      <option value={"DONATION"}>후원</option>
                      <option value={"REWARD_EXCHANGE"}>재화 교환</option>
                    </SelectBox>
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{
                        rotate: "90deg",
                        color: "#2282e9",
                        position: "absolute",
                        top: "22px",
                        right: "17px",
                      }}
                    />
                  </SelectBoxContainer>
                </EditLabelContainer>
              </ContentContainer>
              <ContentContainer>
                <EditLabelContainer>
                  <EditLabel>차감 보석 수</EditLabel>
                  <InputBox
                    type="number"
                    {...register("price")}
                    placeholder="숫자만 입력 가능합니다."
                  />
                </EditLabelContainer>
              </ContentContainer>
              <EditLabelContainer>
                <EditLabel>상세 설명</EditLabel>
                <EditTextArea {...register("description")}></EditTextArea>
              </EditLabelContainer>
            </>
          )}
        </Edit>
      </Inner>
      {cashierCategory === "CODE" && (
        <Management>
          <ManagementBox onClick={() => setOpenManagement((prev) => !prev)}>
            <span>재고 관리</span>
            {openManagement ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </ManagementBox>
          {openManagement && (
            <ManagementContainer>
              <ManagementBtnContainer>
                <ManagementBtn onClick={onManagementModal}>
                  + 직접입력
                </ManagementBtn>
                <input
                  type="file"
                  id="excel"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="excel">
                  <ManagementBtn>+ 엑셀 파일 업로드</ManagementBtn>
                </label>
              </ManagementBtnContainer>
              {codes?.data.page.content &&
                codes?.data.page.content.map((code: any) => (
                  <ManagementRow key={code.id}>
                    <ManagementCode>{code.code}</ManagementCode>
                    <ManagementDate>{code.expiredAt}</ManagementDate>
                    <FontAwesomeIcon
                      icon={faX}
                      style={{
                        color: "#a7a7a7",
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => onDeleteModal(code.id)}
                    />
                  </ManagementRow>
                ))}
              <PaginationWrapper>
                <Pagination
                  activePage={Number(pageNum)}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    codes?.data?.page?.pageable.totalElements || 1
                  }
                  pageRangeDisplayed={5}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                />
              </PaginationWrapper>
            </ManagementContainer>
          )}
        </Management>
      )}
    </Wrapper>
  );
}
