import {
  faChevronDown,
  faChevronUp,
  faCircleMinus,
  faImage,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import MultipleSelectChipGenre from "./MultipleSelectChipGenre";
import MultipleSelectChipPlatform from "./MultipleSelectChipPlatform";
import { clearAll } from "components/common/clearStorage";
import Modal from "./Modal/Modal";
import SurveyPopup from "./Popup/SurveyPopup";
import GalleryGameCreationAPI from "./Data/GalleryGameCreationAPI";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 123px;
  color: #555555;
  font-weight: 600;
  font-size: 22px;
  padding: 0px 50px;
  background-color: #f8f8f8;
  border-radius: 6px;
  cursor: pointer;
`;

const Inner = styled.div`
  border: 1px solid #e3e3e3;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 60px;
  margin-top: -25px;
`;

const ImgInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 16px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgLabel = styled.label`
  border: 3px dashed #d9d9d9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 438px;
  height: 438px;
  cursor: pointer;
`;

const FileBtn = styled.div`
  width: 91px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
  background-color: #f0f0f0;
  border: 1px solid #dee2e6;
  border-radius: 7px;
`;

const FileSpan = styled.span`
  color: #898989;
  display: block;
`;

const ShowThumb = styled.img`
  width: 438px;
  height: 438px;
  border-radius: 20px;
`;

const ShowBackground = styled.img`
  width: 1000px;
  height: 438px;
  border-radius: 20px;
`;

const ShowImg = styled.img`
  width: 438px;
  height: 282px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-left: 20px;
`;

const GameDataInner = styled(Inner)`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Input = styled.input`
  border: 2px solid #2282e9;
  height: 60px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0px 20px;
  width: 502px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 502px;
  height: 60px;
  border-radius: 6px;
  border: 2px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

const DownloadAddBtn = styled.div`
  background-color: #2282e9;
  width: 1032px;
  height: 60px;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 90px;
`;

const TextArea = styled.textarea`
  border: 2px solid #2282e9;
  height: 245px;
  width: 1032px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 20px;
  outline: none;
  resize: none;
`;

const PhaseOneDataInner = styled(Inner)``;

const PhaseTwoDataInner = styled(Inner)``;

const QuestionAddBtn = styled.div`
  border: 2px solid #2282e9;
  width: 1472px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2282e9;
  font-size: 18px;
  border-radius: 50px;
`;

interface SurveyId {
  id: number;
  content: string;
}

export default function GalleryManagementGameAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const galleryId = location.state.galleryId;
  const startedAt = location.state.startedAt;
  const expiredAt = location.state.expiredAt;

  const [img, setImg] = useState(false);
  const [gameData, setGameData] = useState(false);
  const [phaseOneData, setPhaseOneData] = useState(false);
  const [phaseTwoData, setPhaseTwoData] = useState(false);

  const { register, setValue, getValues, watch } = useForm();

  const [modal, setModal] = useState(false);
  const [checkGenres, setCheckGenres] = useState<string[]>([]);
  const [checkPlatforms, setCheckPlatforms] = useState<string[]>([]);
  const [questionId, setQuestionId] = useState<SurveyId[]>([]);

  function close() {
    setModal(false);
  }

  const formData = new FormData();
  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [phaseOne, setPhaseOne] = useState<File | undefined>();
  const [surveyBackground, setSurveyBackground] = useState<File | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  function setLoading() {
    setIsLoading(false);
  }

  const deleteSurvey = () => {
    setShowSurvey(false);
  };

  const handleDelete = (idToDelete: any) => {
    // idToDelete를 가진 항목을 questionId에서 필터링
    const updatedQuestionId = questionId.filter(
      (item) => item.id !== idToDelete
    );
    setQuestionId(updatedQuestionId);
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const handleCheckGenresChange = (newGenres: string[]) => {
    setCheckGenres(newGenres);
  };
  const handleCheckPlatformsChange = (newPlatforms: string[]) => {
    setCheckPlatforms(newPlatforms);
  };

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("thumbnail", selectedImage);
      encodeThumbToBase64(selectedImage);
      setValue("bakground", selectedImage);
    }
  };

  const PhaseOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("phaseOne", selectedImage);
      encodephaseOneToBase64(selectedImage);
    }
  };

  const SurveyBackground = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("surveyBackground", selectedImage);
      encodeSurveyBackgroundToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  function encodephaseOneToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPhaseOne(file);
    };
  }

  function encodeSurveyBackgroundToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSurveyBackground(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  async function CallAPI() {
    const titleValue = getValues("title");
    const companyValue = getValues("company");
    const appStoreLinkValue = getValues("appStoreLink");
    const playStoreLinkValue = getValues("playStoreLink");
    const pcDownloadLinkValue = getValues("pcDownloadLink");
    const youtubeLinkValue = getValues("youtubeLink");
    const youtubeLinkWebValue = getValues("youtubeLinkWeb");
    const genresValue = checkGenres;
    const platformsValue = checkPlatforms;
    const descriptionValue = getValues("description");
    const visibleValue = getValues("visible");
    const categoryValue = "RELEASE";
    const phaseOneDescriptionValue = getValues("phaseOneDescription");
    const phaseOneExampleHorizontalValue = getValues(
      "phaseOneExampleHorizontal"
    );
    const galleryInfoValue = {
      galleryId: galleryId,
    };

    const inputs = {
      packageId: 0,
      exp: 0,
      rewards: 0,
      allowedPlayTime: 0,
      cheeringPlistar: 0,
      reviewPlistar: 0,
      startedAt: startedAt,
      expiredAt: expiredAt,
      title: titleValue,
      company: companyValue,
      appStoreLink: appStoreLinkValue !== undefined ? appStoreLinkValue : "",
      playStoreLink: playStoreLinkValue !== undefined ? playStoreLinkValue : "",
      pcDownloadLink:
        pcDownloadLinkValue !== undefined ? pcDownloadLinkValue : "",
      youtubeLink: youtubeLinkValue ? youtubeLinkValue : "",
      youtubeLinkWeb: youtubeLinkWebValue ? youtubeLinkWebValue : "",
      genres: genresValue,
      platforms: platformsValue,
      description: descriptionValue,
      visible: visibleValue,
      category: categoryValue,

      phaseOneDescription: phaseOneDescriptionValue,
      phaseOneExampleHorizontal: phaseOneExampleHorizontalValue,

      questionIds: questionId.map((item) => item.id),

      eventHost: "GALLERY",
      galleryInfo: galleryInfoValue,
    };

    console.log(inputs);
    const blob = new Blob([JSON.stringify(inputs)], {
      type: "application/json",
    });

    formData.append("data", blob);

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
      formData.append("background", thumbnail);
    }

    if (surveyBackground) {
      formData.append("surveyBackground", surveyBackground);
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    if (phaseOne) {
      formData.append("phaseOneExample", phaseOne);
    }

    await gameAddMutation.mutateAsync(formData);
  }

  const watchYoutubeLink = watch("watchYoutubeLink");

  const gameAddMutation = useMutation(GalleryGameCreationAPI, {
    onSuccess: () => {
      setIsLoading(false);
      alert("게임 등록이 완료되었습니다.");
      setModal(false);
      window.location.reload();
      navigate(-1);
    },
    onError: () => {
      setIsLoading(false);
      alert("게임 등록에 실패했습니다. 새로고침 후 다시 시도해주세요.");
      setModal(false);
    },
  });

  useEffect(() => {
    clearAll();
    setValue("watchYoutubeLink", "true");
  }, []);

  function checkBody() {
    const titleValue = getValues("title");
    const descriptionValue = getValues("description");
    const phaseOneExampleHorizontalValue = getValues(
      "phaseOneExampleHorizontal"
    );
    const companyValue = getValues("company");
    const genresValue = checkGenres;
    const phaseOneDescriptionValue = getValues("phaseOneDescription");
    const visibleValue = getValues("visible");
    const platformsValue = checkPlatforms;

    if (
      !titleValue ||
      !descriptionValue ||
      !companyValue ||
      !genresValue ||
      !platformsValue ||
      !phaseOneDescriptionValue ||
      !phaseOneExampleHorizontalValue ||
      !visibleValue ||
      !thumbnail ||
      !images ||
      !surveyBackground ||
      !questionId
    ) {
      alert("모든 항목을 입력하세요");
    } else {
      setModal(true);
    }
  }

  return (
    <Wrapper>
      {modal && (
        <Modal
          close={close}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      {showSurvey && (
        <SurveyPopup
          close={deleteSurvey}
          setQuestionId={setQuestionId}
          questionId={questionId}
        />
      )}
      <HeaderContainer>
        <Title>게임 등록</Title>
        <Button onClick={checkBody}>등록하기</Button>
      </HeaderContainer>
      <ContentContainer>
        <Content onClick={() => setImg((prev) => !prev)}>
          이미지
          {img ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {img && (
          <ImgInner>
            <div style={{ display: "flex", gap: "30px", marginBottom: "32px" }}>
              <ImgContainer>
                <SubTitle>썸네일</SubTitle>
                <ImgLabel htmlFor="thumbnail">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={Thumbnail}
                    style={{ display: "none" }}
                    id="thumbnail"
                  />
                  {thumbnail ? (
                    <div>
                      <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{
                          width: "39px",
                          height: "33px",
                          color: "#d1d1d1",
                          marginBottom: "20px",
                        }}
                      />
                      <FileBtn>파일 선택</FileBtn>
                      <FileSpan>*선택된 파일 없음</FileSpan>
                    </>
                  )}
                </ImgLabel>
              </ImgContainer>
              <ImgContainer>
                <SubTitle>배경</SubTitle>
                <ImgLabel
                  style={{ width: "1000px" }}
                  htmlFor="surveyBackground"
                >
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={SurveyBackground}
                    style={{ display: "none" }}
                    id="surveyBackground"
                  />
                  {surveyBackground ? (
                    <ShowBackground
                      src={URL.createObjectURL(surveyBackground)}
                      alt=""
                    />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{
                          width: "39px",
                          height: "33px",
                          color: "#d1d1d1",
                          marginBottom: "20px",
                        }}
                      />
                      <FileBtn>파일 선택</FileBtn>
                      <FileSpan>*선택된 파일 없음</FileSpan>
                    </>
                  )}
                </ImgLabel>
              </ImgContainer>
            </div>
            <div style={{ display: "flex" }}>
              <ImgContainer>
                <ImgLabel style={{ height: "282px" }} htmlFor="image">
                  <input
                    ref={fileRef}
                    type="file"
                    multiple
                    accept=".jpg, .png"
                    onChange={Image}
                    style={{ display: "none" }}
                    id="image"
                  />
                  <SubTitle>게임 이미지</SubTitle>
                  <FileSpan style={{ marginBottom: "20px" }}>
                    *최대 5장까지 추가 가능합니다.
                  </FileSpan>
                  <FileBtn>파일 선택</FileBtn>
                </ImgLabel>
              </ImgContainer>
              <PrevDiv>
                {showImages?.map((url, idx) => (
                  <ShowImg
                    key={url}
                    src={url}
                    onClick={() => imageDelete(idx, url)}
                  />
                ))}
              </PrevDiv>
            </div>
          </ImgInner>
        )}
        <Content onClick={() => setGameData((prev) => !prev)}>
          게임 데이터
          {gameData ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {gameData && (
          <GameDataInner>
            <div style={{ display: "flex", gap: "28px" }}>
              <LabelContainer>
                <Label>게임 이름</Label>
                <Input type="text" max="255" {...register("title")} />
              </LabelContainer>
              <LabelContainer>
                <Label>게임사</Label>
                <Input type="text" max="255" {...register("company")} />
              </LabelContainer>
            </div>
            <div style={{ display: "flex", gap: "28px" }}>
              <LabelContainer>
                <Label>안드로이드</Label>
                <Input type="text" max="255" {...register("playStoreLink")} />
              </LabelContainer>
              <LabelContainer>
                <Label>IOS</Label>
                <Input type="text" max="255" {...register("appStoreLink")} />
              </LabelContainer>
            </div>
            <div style={{ display: "flex", gap: "28px" }}>
              <LabelContainer>
                <Label>PC</Label>
                <Input type="text" max="255" {...register("pcDownloadLink")} />
              </LabelContainer>
            </div>
            {/* <div style={{ display: "flex", gap: "28px" }}>
              <LabelContainer>
                <Label>다운로드 방식</Label>
                <SelectBoxContainer>
                  <SelectBox>
                    <option value="pc">PC 다운로드</option>
                    <option value="ios">IOS 다운로드</option>
                    <option value="android">안드로이드 다운로드</option>
                  </SelectBox>
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{
                      rotate: "90deg",
                      color: "#2282e9",
                      position: "absolute",
                      top: "22px",
                      right: "17px",
                    }}
                  />
                </SelectBoxContainer>
              </LabelContainer>
              <LabelContainer>
                <Input style={{ marginTop: "43px" }} />
              </LabelContainer>
            </div> */}
            {/* <DownloadAddBtn
              onClick={() => {
                append({});
              }}
            >
              +다운로드 방식 추가하기
            </DownloadAddBtn> */}
            <div>
              <LabelContainer>
                <Label>유튜브 링크</Label>
                <RadioContainer>
                  <div>
                    <input
                      type="radio"
                      value="true"
                      {...register("watchYoutubeLink")}
                    />
                    <label>있음</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="false"
                      {...register("watchYoutubeLink")}
                    />
                    <label>없음</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </div>
            {watchYoutubeLink === "true" && (
              <div style={{ display: "flex", gap: "28px" }}>
                <LabelContainer>
                  <Label>Web 유튜브 링크</Label>
                  <Input type="text" {...register("youtubeLinkWeb")} />
                </LabelContainer>
                <LabelContainer>
                  <Label>모바일 유튜브 링크 </Label>
                  <Input type="text" {...register("youtubeLink")} />
                </LabelContainer>
              </div>
            )}
            <div style={{ display: "flex", gap: "28px" }}>
              <LabelContainer>
                <Label>장르</Label>
                <div>
                  {
                    <MultipleSelectChipGenre
                      checkOptions={checkGenres}
                      onCheckOptionsChange={handleCheckGenresChange}
                    />
                  }
                </div>
              </LabelContainer>
              <LabelContainer>
                <Label>플랫폼</Label>
                <div>
                  {
                    <MultipleSelectChipPlatform
                      checkOptions={checkPlatforms}
                      onCheckOptionsChange={handleCheckPlatformsChange}
                    />
                  }
                </div>
              </LabelContainer>
            </div>
            <div>
              <LabelContainer>
                <Label>게임 상세 설명</Label>
                <TextArea
                  rows={8}
                  maxLength={2000}
                  {...register("description")}
                />
              </LabelContainer>
            </div>
            <div>
              <LabelContainer>
                <Label>어플표시</Label>
                <RadioContainer>
                  <div>
                    <input type="radio" value="true" {...register("visible")} />
                    <label>보이기</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="false"
                      {...register("visible")}
                    />
                    <label>숨기기</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </div>
          </GameDataInner>
        )}
        <Content onClick={() => setPhaseOneData((prev) => !prev)}>
          1단계 데이터
          {phaseOneData ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {phaseOneData && (
          <PhaseOneDataInner>
            <LabelContainer>
              <Label>1단계 설명</Label>
              <TextArea
                rows={8}
                maxLength={2000}
                {...register("phaseOneDescription")}
              />
            </LabelContainer>
            <div style={{ display: "flex", gap: "90px" }}>
              <ImgContainer>
                <SubTitle>1단계 예시 이미지 파일</SubTitle>
                <ImgLabel htmlFor="phaseOne">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={PhaseOne}
                    style={{ display: "none" }}
                    id="phaseOne"
                  />
                  {phaseOne ? (
                    <div>
                      <ShowThumb src={URL.createObjectURL(phaseOne)} alt="" />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{
                          width: "39px",
                          height: "33px",
                          color: "#d1d1d1",
                          marginBottom: "20px",
                        }}
                      />
                      <FileBtn>파일 선택</FileBtn>
                      <FileSpan>*선택된 파일 없음</FileSpan>
                    </>
                  )}
                </ImgLabel>
              </ImgContainer>
              <LabelContainer>
                <Label>예시 이미지 수평 방향</Label>
                <RadioContainer>
                  <div>
                    <input
                      type="radio"
                      value="true"
                      {...register("phaseOneExampleHorizontal")}
                    />
                    <label>수평</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="false"
                      {...register("phaseOneExampleHorizontal")}
                    />
                    <label>수직</label>
                  </div>
                </RadioContainer>
              </LabelContainer>
            </div>
          </PhaseOneDataInner>
        )}
        <Content onClick={() => setPhaseTwoData((prev) => !prev)}>
          2단계 데이터
          {phaseTwoData ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {phaseTwoData && (
          <PhaseTwoDataInner>
            <LabelContainer>
              <Label>설문 질문 등록하기</Label>
              <QuestionAddBtn onClick={() => setShowSurvey(true)}>
                + 질문 추가하기
              </QuestionAddBtn>
            </LabelContainer>

            {questionId.map((id) => {
              return (
                <Chip
                  key={id.id}
                  label={id.content}
                  onClick={() => handleDelete(id.id)}
                />
              );
            })}
          </PhaseTwoDataInner>
        )}
      </ContentContainer>
    </Wrapper>
  );
}
