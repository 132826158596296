import {
  faChevronDown,
  faChevronUp,
  faImage,
  faPlay,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "./Modal/Modal";
import GalleryGameModification from "./Data/GalleryModificationAPI";
import GalleryDetailAPI from "./Data/GalleryDetailAPI";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 123px;
  color: #555555;
  font-weight: 600;
  font-size: 22px;
  padding: 0px 50px;
  background-color: #f8f8f8;
  border-radius: 6px;
  cursor: pointer;
`;

const Inner = styled.div`
  border: 1px solid #e3e3e3;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 60px;
  margin-top: -25px;
`;

const WebBannerInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  row-gap: 50px;
  overflow-x: scroll;
`;

const SubTitle = styled.div`
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 18px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgLabel = styled.label`
  border: 3px dashed #d9d9d9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 355px;
  height: 355px;
  cursor: pointer;
`;

const FileBtn = styled.div`
  width: 91px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
  background-color: #f0f0f0;
  border: 1px solid #dee2e6;
  border-radius: 7px;
`;

const FileSpan = styled.span`
  color: #898989;
  display: block;
  margin-bottom: 8px;
`;

const ImgAdd = styled.div`
  width: 75px;
  height: 355px;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-top: 45px;
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const GameInner = styled(Inner)`
  display: flex;
  gap: 52px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Input = styled.input`
  border: 2px solid #2282e9;
  height: 60px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0px 20px;
`;

const DateInput = styled.input`
  width: 529px;
  height: 60px;
  box-sizing: border-box;
  padding: 0px 20px;
  border: 2px solid #2282e9;
  border-radius: 6px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 529px;
  height: 60px;
  border-radius: 6px;
  border: 2px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

const TextArea = styled.textarea`
  border: 2px solid #2282e9;
  height: 245px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 20px;
  outline: none;
  resize: none;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 90px;
`;

const GemSettingInner = styled(Inner)`
  display: flex;
  flex-direction: column;
`;

const GemButton = styled.div`
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  gap: 11px;
`;

const NumButton = styled(GemButton)`
  background-color: #2282e9;
  color: #fff;
`;

const PlusButton = styled(GemButton)`
  background-color: #e3e3e3;
  color: #bdbdbd;
`;

const ShowBanner = styled.img`
  width: 355px;
  height: 355px;
  border-radius: 20px;
`;

const ShowSubBanner = styled.img`
  width: 355px;
  height: 355px;
  border-radius: 20px;
`;

const ShowBallot = styled.img`
  width: 355px;
  height: 355px;
  border-radius: 20px;
`;

const ShowImg = styled.img`
  width: 355px;
  height: 355px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: 42px;
`;

const Img = styled.img`
  width: 355px;
  height: 355px;
  border-radius: 20px;
`;

export default function GalleryManagementDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const galleryId = location.state.id;

  const [webBannerImg, setWebBannerImg] = useState(false);
  const [appBannerImg, setAppBannerImg] = useState(false);
  const [gameData, setGameData] = useState(false);
  const [gemSetting, setGemSetting] = useState(false);

  const { register, setValue, getValues, control, watch } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "galleryRewardRecords",
  });
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function close() {
    setModal(false);
  }

  const formData = new FormData();
  const [banner, setBanner] = useState<File | undefined>();
  const [subBanner, setSubBanner] = useState<File | undefined>();
  const [ballot, setBallot] = useState<File | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);
  const [imagesWeb, setImagesWeb] = useState<File[]>([]);
  const [showImagesWeb, setShowImagesWeb] = useState<string[]>([]);
  const [showRewardRecords, setShowRewardRecores] = useState(0);
  const [originImages, setOriginImages] = useState<any[]>([]);
  const [originImagesWeb, setOriginImagesWeb] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  function setLoading() {
    setIsLoading(false);
  }

  const fileRef = useRef<HTMLInputElement>(null);
  const fileWebRef = useRef<HTMLInputElement>(null);

  const Banner = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("banner", selectedImage);
      encodeBannerToBase64(selectedImage);
    }
  };

  const SubBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("subBanner", selectedImage);
      encodeSubBannerToBase64(selectedImage);
    }
  };

  const Ballot = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("ballot", selectedImage);
      encodeBallotToBase64(selectedImage);
    }
  };

  function encodeBannerToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBanner(file);
    };
  }

  function encodeSubBannerToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSubBanner(file);
    };
  }

  function encodeBallotToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBallot(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  const ImageWeb = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = imagesWeb.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImagesWeb((prev) => prev.concat(targetFilesArray));

        setShowImagesWeb((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageWebDelete = (idx: number, url: string) => {
    setShowImagesWeb(showImages!.filter((e) => e !== url));

    setImagesWeb([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  const { data: galleryDetail } = useQuery(
    ["galleryDetail", galleryId],
    async () => {
      if (galleryId !== undefined) {
        return await GalleryDetailAPI(Number(galleryId));
      }
    }
  );

  const handleVisibleChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  const visibleValue = watch("visible");

  async function CallAPI() {
    const titleValue = getValues("title");
    const ballotColorCodeValue = getValues("ballotColorCode");
    const gemsPerBallotValue = getValues("gemsPerBallot");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const voteStartedAtValue = getValues("voteStartedAt");
    const voteExpiredAtValue = getValues("voteExpiredAt");
    const userAgreementValue = getValues("userAgreement");
    const maxDuplicateVotesValue = getValues("maxDuplicateVotes");
    const visibleValue = getValues("visible");
    const galleryRewardRecordsValue = getValues("galleryRewardRecords");

    // const inputArray: {
    //   rewardCategory: string;
    //   rewards: string;
    //   requiredCompletions: string;
    // }[] = galleryRewardRecordsValue;

    // for (let i = 0; i < inputArray.length; i++) {
    //   formData.append(
    //     "galleryRewardRecords[" + i + "].rewardCategory",
    //     inputArray[i].rewardCategory
    //   );

    //   formData.append(
    //     "galleryRewardRecords[" + i + "].rewards",
    //     inputArray[i].rewards
    //   );

    //   formData.append(
    //     "galleryRewardRecords[" + i + "].requiredCompletions",
    //     inputArray[i].requiredCompletions
    //   );
    // }

    const inputs = {
      title: titleValue,
      ballotColorCode: ballotColorCodeValue,
      gemsPerBallot: gemsPerBallotValue,
      startedAt: startedAtValue,
      expiredAt: expiredAtValue,
      voteStartedAt: voteStartedAtValue,
      voteExpiredAt: voteExpiredAtValue,
      userAgreement: userAgreementValue,
      maxDuplicateVotes: maxDuplicateVotesValue,
      visible: visibleValue,
      galleryRewardRecords: galleryRewardRecordsValue,
    };

    const blob = new Blob([JSON.stringify(inputs)], {
      type: "application/json",
    });

    formData.append("data", blob);

    if (banner) {
      formData.append("banner", banner);
    }

    if (subBanner) {
      formData.append("subBanner", subBanner);
    }

    if (ballot) {
      formData.append("ballot", ballot);
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    if (imagesWeb) {
      for (let i = 0; i < imagesWeb.length; i++) {
        formData.append("imagesWeb", imagesWeb[i]);
      }
    }

    await galleryEditMutation.mutateAsync(formData);
  }

  const galleryEditMutation = useMutation(
    (formData: FormData) =>
      GalleryGameModification(Number(galleryId), formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("galleryEdit");
        close();
        setIsLoading(false);
        alert("수정이 완료되었습니다.");
        navigate(-1);
      },
      onError: (error) => {
        setIsLoading(false);
        alert("Error: " + error);
        close();
      },
    }
  );

  const fetchData = async () => {
    const response = await GalleryDetailAPI(Number(galleryId));

    if (response) {
      setValue("title", response.data.galleryDetails.title);
      setValue("ballotColorCode", response.data.galleryDetails.ballotColorCode);
      setValue("gemsPerBallot", response.data.galleryDetails.gemsPerBallot);
      setValue("startedAt", response.data.galleryDetails.startedAt);
      setValue("expiredAt", response.data.galleryDetails.expiredAt);
      setValue("voteStartedAt", response.data.galleryDetails.voteStartedAt);
      setValue("voteExpiredAt", response.data.galleryDetails.voteExpiredAt);
      setValue("userAgreement", response.data.galleryDetails.userAgreement);
      setValue(
        "maxDuplicateVotes",
        response.data.galleryDetails.maxDuplicateVotes
      );
      setValue("visible", response.data.galleryDetails.visible);
      setOriginImages(response.data.galleryDetails.galleryBannerUrls);
      setOriginImagesWeb(response.data.galleryDetails.galleryBannerUrlsWeb);
      setValue("galleryRewardRecords", response.data.rewardDetails);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function checkBody() {
    const titleValue = getValues("title");
    const ballotColorCodeValue = getValues("ballotColorCode");
    const gemsPerBallotValue = getValues("gemsPerBallot");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const voteStartedAtValue = getValues("voteStartedAt");
    const voteExpiredAtValue = getValues("voteExpiredAt");
    const userAgreementValue = getValues("userAgreement");
    const maxDuplicateVotesValue = getValues("maxDuplicateVotes");
    const visibleValue = getValues("visible");

    if (
      !titleValue ||
      !ballotColorCodeValue ||
      !gemsPerBallotValue ||
      !startedAtValue ||
      !expiredAtValue ||
      !voteStartedAtValue ||
      !voteExpiredAtValue ||
      !userAgreementValue ||
      !maxDuplicateVotesValue ||
      !visibleValue
    ) {
      alert("모든 항목을 입력하세요");
    } else {
      setModal(true);
    }
  }

  return (
    <Wrapper>
      {modal && (
        <Modal
          close={close}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      <HeaderContainer>
        <Title>온라인 전시관 수정</Title>
        <Button onClick={checkBody}>수정완료</Button>
      </HeaderContainer>
      <ContentContainer>
        <Content onClick={() => setWebBannerImg((prev) => !prev)}>
          Web 배너 이미지 등록
          {webBannerImg ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {webBannerImg && (
          <WebBannerInner>
            <div style={{ display: "flex", gap: "30px" }}>
              <ImgContainer>
                <SubTitle>전시관 내부 배너 (웹)</SubTitle>
                <ImgLabel htmlFor="banner">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={Banner}
                    style={{ display: "none" }}
                    id="banner"
                  />
                  {banner ? (
                    <div>
                      <ShowBanner src={URL.createObjectURL(banner)} alt="" />
                    </div>
                  ) : (
                    <Img
                      src={galleryDetail?.data.galleryDetails.bannerUrl}
                      alt=""
                    />
                  )}
                </ImgLabel>
              </ImgContainer>
              <ImgContainer>
                <SubTitle>게임사 로고</SubTitle>
                <ImgLabel htmlFor="subBanner">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={SubBanner}
                    style={{ display: "none" }}
                    id="subBanner"
                  />
                  {subBanner ? (
                    <div>
                      <ShowSubBanner
                        src={URL.createObjectURL(subBanner)}
                        alt=""
                      />
                    </div>
                  ) : (
                    <Img
                      src={galleryDetail?.data.galleryDetails.subBannerUrl}
                      alt=""
                    />
                  )}
                </ImgLabel>
              </ImgContainer>
              <ImgContainer>
                <SubTitle>투표권 이미지</SubTitle>
                <ImgLabel htmlFor="ballot">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={Ballot}
                    style={{ display: "none" }}
                    id="ballot"
                  />
                  {ballot ? (
                    <div>
                      <ShowBallot src={URL.createObjectURL(ballot)} alt="" />
                    </div>
                  ) : (
                    <Img
                      src={galleryDetail?.data.galleryDetails.ballotUrl}
                      alt=""
                    />
                  )}
                </ImgLabel>
              </ImgContainer>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SubTitle>기존 메인페이지 배너</SubTitle>
              <div
                style={{ display: "flex", gap: "12px", marginBottom: "20px" }}
              >
                <PrevDiv style={{ marginLeft: "0px", marginTop: "0px" }}>
                  {originImagesWeb &&
                    originImagesWeb.map((url, idx) => (
                      <ShowImg
                        style={{ cursor: "default" }}
                        key={idx}
                        src={url.galleryBannerUrl}
                        alt=""
                      />
                    ))}
                </PrevDiv>
              </div>
              <ImgContainer>
                <SubTitle>메인페이지 배너</SubTitle>
                <ImgLabel htmlFor="imageWeb">
                  <input
                    ref={fileRef}
                    type="file"
                    multiple
                    accept=".jpg, .png"
                    onChange={ImageWeb}
                    style={{ display: "none" }}
                    id="imageWeb"
                  />
                  <FontAwesomeIcon
                    icon={faImage}
                    style={{
                      width: "39px",
                      height: "33px",
                      color: "#d1d1d1",
                      marginBottom: "20px",
                    }}
                  />
                  <FileBtn>파일 선택</FileBtn>
                </ImgLabel>
              </ImgContainer>
              <PrevDiv>
                {showImagesWeb?.map((url, idx) => (
                  <ShowImg
                    key={url}
                    src={url}
                    onClick={() => imageWebDelete(idx, url)}
                  />
                ))}
              </PrevDiv>
            </div>
          </WebBannerInner>
        )}
        <Content onClick={() => setAppBannerImg((prev) => !prev)}>
          App 배너 이미지 등록
          {appBannerImg ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {appBannerImg && (
          <AppInner>
            <SubTitle>기존 메인페이지 내부 배너</SubTitle>
            <div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
              <PrevDiv style={{ marginLeft: "0px", marginTop: "0px" }}>
                {originImages &&
                  originImages.map((url, idx) => (
                    <ShowImg
                      style={{ cursor: "default" }}
                      key={idx}
                      src={url.galleryBannerUrl}
                      alt=""
                    />
                  ))}
              </PrevDiv>
            </div>
            <ImgContainer>
              <SubTitle>메인페이지 내부 배너</SubTitle>
              <ImgLabel htmlFor="image">
                <input
                  ref={fileWebRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  onChange={Image}
                  style={{ display: "none" }}
                  id="image"
                />
                <FontAwesomeIcon
                  icon={faImage}
                  style={{
                    width: "39px",
                    height: "33px",
                    color: "#d1d1d1",
                    marginBottom: "20px",
                  }}
                />
                <FileBtn>파일 선택</FileBtn>
                <FileSpan>*선택된 파일 없음</FileSpan>
              </ImgLabel>
            </ImgContainer>
            <PrevDiv>
              {showImages?.map((url, idx) => (
                <ShowImg
                  key={url}
                  src={url}
                  onClick={() => imageDelete(idx, url)}
                />
              ))}
            </PrevDiv>
          </AppInner>
        )}
        <Content onClick={() => setGameData((prev) => !prev)}>
          게임 데이터
          {gameData ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {gameData && (
          <GameInner>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <LabelContainer>
                <Label>전시관 이름</Label>
                <Input
                  type="text"
                  {...register("title")}
                  style={{ width: "1079px" }}
                />
              </LabelContainer>
              <div style={{ display: "flex", gap: "21px" }}>
                <LabelContainer>
                  <Label>전시 시작일</Label>
                  <DateInput
                    type="datetime-local"
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("startedAt")}
                  />
                </LabelContainer>
                <LabelContainer>
                  <Label>전시 종료일</Label>
                  <DateInput
                    type="datetime-local"
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("expiredAt")}
                  />
                </LabelContainer>
              </div>
              <div style={{ display: "flex", gap: "21px" }}>
                <LabelContainer>
                  <Label>투표 시작일</Label>
                  <DateInput
                    type="datetime-local"
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("voteStartedAt")}
                  />
                </LabelContainer>
                <LabelContainer>
                  <Label>투표 종료일</Label>
                  <DateInput
                    type="datetime-local"
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("voteExpiredAt")}
                  />
                </LabelContainer>
              </div>
              <div style={{ display: "flex", gap: "21px" }}>
                <LabelContainer>
                  <Label>투표권 재화 가치</Label>
                  <Input
                    type="number"
                    {...register("gemsPerBallot")}
                    style={{ width: "529px" }}
                  />
                </LabelContainer>
                <LabelContainer>
                  <Label>중복투표 가능 수</Label>
                  <Input
                    type="number"
                    {...register("maxDuplicateVotes")}
                    style={{ width: "529px" }}
                  />
                </LabelContainer>
              </div>
              <div>
                <LabelContainer>
                  <Label>전시관 참여 이용 안내</Label>
                  <TextArea maxLength={1000} {...register("userAgreement")} />
                </LabelContainer>
              </div>
              <div>
                <LabelContainer>
                  <Label>공개 표시</Label>
                  <RadioContainer>
                    <div>
                      <input
                        type="radio"
                        value="true"
                        checked={visibleValue === true}
                        {...register("visible")}
                        onChange={handleVisibleChange}
                      />
                      <label>보이기</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="false"
                        checked={visibleValue === false}
                        {...register("visible")}
                        onChange={handleVisibleChange}
                      />
                      <label>숨기기</label>
                    </div>
                  </RadioContainer>
                </LabelContainer>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <LabelContainer>
                <Label>투표권 색상코드</Label>
                <Input
                  type="text"
                  {...register("ballotColorCode")}
                  style={{ width: "529px" }}
                />
              </LabelContainer>
            </div>
          </GameInner>
        )}
        <Content onClick={() => setGemSetting((prev) => !prev)}>
          구간별 보석 설정
          {gemSetting ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Content>
        {gemSetting && (
          <GemSettingInner>
            <ButtonContainer>
              {fields.map((item, index) => {
                return (
                  <NumButton
                    key={item.id}
                    onClick={() => setShowRewardRecores(index)}
                  >
                    {index + 1}
                  </NumButton>
                );
              })}
              <PlusButton onClick={() => append({})}>+</PlusButton>
            </ButtonContainer>
            {fields.map((item, index) => {
              return (
                showRewardRecords === index && (
                  <div key={item.id}>
                    <LabelContainer>
                      <Label>보상 카테고리</Label>
                      <SelectBoxContainer style={{ width: "1079px" }}>
                        <SelectBox
                          style={{ width: "1079px" }}
                          {...register(
                            `galleryRewardRecords.${index}.rewardCategory`
                          )}
                        >
                          <option value={"GEM"}>보석</option>
                          <option value={"BALLOT"}>투표권</option>
                          <option value={"POINT"}>포인트</option>
                        </SelectBox>
                        <FontAwesomeIcon
                          icon={faPlay}
                          style={{
                            rotate: "90deg",
                            color: "#2282e9",
                            position: "absolute",
                            top: "22px",
                            right: "17px",
                          }}
                        />
                      </SelectBoxContainer>
                    </LabelContainer>
                    <div style={{ display: "flex", gap: "21px" }}>
                      <LabelContainer>
                        <Label>게임 완료 수</Label>
                        <Input
                          style={{ width: "529px" }}
                          type="number"
                          {...register(
                            `galleryRewardRecords.${index}.requiredCompletions`
                          )}
                        />
                      </LabelContainer>
                      <LabelContainer>
                        <Label>보상 수</Label>
                        <Input
                          style={{ width: "529px" }}
                          type="number"
                          {...register(`galleryRewardRecords.${index}.rewards`)}
                        />
                      </LabelContainer>
                    </div>
                  </div>
                )
              );
            })}
          </GemSettingInner>
        )}
      </ContentContainer>
    </Wrapper>
  );
}
