export function bannerCategoryText(category: string) {
  if (category === "HOME_BANNER") {
    return "홈 배너";
  } else if (category === "HOME_SUB_BANNER") {
    return "홈 서브 배너 배너";
  } else if (category === "COMMUNITY_BANNER") {
    return "커뮤니티 배너";
  } else if (category === "CONTENTS_BANNER") {
    return "콘텐츠";
  } else if (category === "POINT_SHOP_BANNER") {
    return "포인트샵";
  } else if (category === "INVITATION_BANNER") {
    return "친구초대";
  } else if (category === "CALENDAR_BANNER") {
    return "캘린더";
  } else if (category === "GALLERY_BANNER") {
    return "온라인 전시관 메인 페이지 배너";
  } else if (category === "GALLERY_BANNER_WEB") {
    return "온라인 전시관 메인 페이지 배너(web)";
  } else if (category === "GALLERY_POINT_SHOP_BANNER") {
    return "온라인 전시관 포인트샵 배너";
  } else if (category === "GALLERY_POINT_SHOP_BANNER_WEB") {
    return "온라인 전시관 포인트샵 배너(web)";
  } else {
    return ""; // 다른 경우에는 빈 문자열 반환
  }
}
