import instance from "API/MainAPI";

export default function GalleryCreationAPI(bodyFormData: FormData) {
  return instance.post("/v3/admins/galleries/managements", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}
