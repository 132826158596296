import instance from "API/MainAPI";

export default function GalleryManagementGameListAPI(galleryId: number) {
  return instance.get("/v3/admins/games/managements", {
    params: {
      pageSize: 9999,
      pageNumber: 0,
      galleryId,
    },
  });
}
