import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

const Tab = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
`;

const SelectedTab = styled.li`
  background-color: #2282e9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 18px 0;
  text-align: center;
  list-style: none;
  margin: 0;
  width: 100%;
`;

const UnselectedTab = styled.li`
  background-color: #f8f8f8;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 18px 0;
  text-align: center;
  list-style: none;
  margin: 0;
  width: 100%;
`;

interface Filter {
  userId: number | null;
  gameId: number | null;
  status: String | null;
}

function GalleryTestManage() {
  const param = useParams();
  const location = useLocation();
  const url = location.pathname;

  return (
    <div
      style={{
        flex: "4",
        zIndex: "1",
        position: "relative",
        margin: "0px 40px",
      }}
    >
      <div>
        {url === `/gallery/test/${param.galleryId}/phaseOne` ? (
          <Tab>
            <Link
              to="phaseOne"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <SelectedTab>게임 테스트 1단계 인증</SelectedTab>
            </Link>
            <Link
              to="phaseTwo"
              style={{ textDecoration: "none", color: "black", width: "100%" }}
            >
              <UnselectedTab>게임 테스트 2단계 인증</UnselectedTab>
            </Link>
          </Tab>
        ) : (
          <Tab>
            <Link
              to="phaseOne"
              style={{ textDecoration: "none", width: "50%", color: "black" }}
            >
              <UnselectedTab>게임 테스트 1단계 인증</UnselectedTab>
            </Link>
            <Link
              to="phaseTwo"
              style={{ textDecoration: "none", width: "50%" }}
            >
              <SelectedTab>게임 테스트 2단계 인증</SelectedTab>
            </Link>
          </Tab>
        )}
      </div>
      <Outlet />
    </div>
  );
}

export default GalleryTestManage;
