import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import GalleryOrderDetailAPI from "./Data/GalleryOrderDetailAPI";
import formatDate from "components/common/function/formatDate";
import { useState } from "react";
import Modal from "./Modal/Modal";
import GalleryOrderCompletionAPI from "./Data/GalleryOrderCompletionAPI";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Inner = styled.div`
  display: flex;
  gap: 30px;
`;

const OrderInfo = styled.div`
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 20px;
`;
const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #262626;
  margin-bottom: 16px;
`;

const Img = styled.img`
  width: 305px;
  height: 305px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  width: 43px;
`;

const Element = styled.div`
  background-color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  color: #555555;
  border-radius: 10px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  height: 49px;
  width: 255px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  padding: 20px;
  border-radius: 10px;
`;

const ConsumerInfo = styled.div`
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 20px;
`;

const SendBtn = styled.div`
  background-color: #2282e9;
  width: 635px;
  height: 78px;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 180px;
  cursor: pointer;
`;

export default function GalleryOrderDetail() {
  const location = useLocation();
  const id = location.state.id;
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data: orderDetail } = useQuery(["orderDetail", id], () =>
    GalleryOrderDetailAPI(id)
  );

  function close() {
    setModal(false);
  }

  const onClickSend = () => {
    setModal(true);
  };

  async function CallAPI() {
    const requestData = {
      consumerId: orderDetail?.data.consumer.id,
      productId: orderDetail?.data.product.id,
    };
    console.log(requestData);
    await PushSendMutation.mutateAsync(requestData);
  }

  const PushSendMutation = useMutation(
    (requestData: any) => GalleryOrderCompletionAPI(id, requestData),
    {
      onSuccess: () => {
        setIsLoading(false);
        alert("발송 완료 처리했습니다.");
        setModal(false);
        window.location.reload();
      },
      onError: () => {
        setIsLoading(false);
        alert("발송 완료 처리를 실패했습니다. 다시 시도하세요.");
        setModal(false);
      },
    }
  );

  return (
    <Wrapper>
      {modal && <Modal close={close} CallAPI={CallAPI} isLoading={isLoading} />}
      <HeaderContainer>
        <Title>주문 세부 내용 </Title>
      </HeaderContainer>
      <Inner>
        <OrderInfo>
          <SubTitle>주문정보</SubTitle>
          <div style={{ display: "flex", gap: "30px" }}>
            <Img src={orderDetail?.data.product.bannerUrl} alt="예시 이미지" />
            <InfoContainer>
              <LabelContainer>
                <Label>제품명</Label>
                <Element>{orderDetail?.data.product.subtitle}</Element>
              </LabelContainer>
              <LabelContainer>
                <Label>브랜드</Label>
                <Element>{orderDetail?.data.product.title}</Element>
              </LabelContainer>
              <LabelContainer>
                <Label>분류</Label>
                <Element>
                  {orderDetail?.data.product.category === "MOBILE_COUPON"
                    ? "모바일 상품 교환 쿠폰"
                    : orderDetail?.data.product.category === "GOOGLE_PLAY"
                    ? "구글플레이 기프트 쿠폰"
                    : orderDetail?.data.product.category === "EXP"
                    ? "경험치"
                    : orderDetail?.data.product.category === "GAME_GIFT_CARD"
                    ? "게임 기프트 카드"
                    : orderDetail?.data.product.category === "CAFE_BAKERY"
                    ? "카페,베이커리"
                    : orderDetail?.data.product.category === "CONVENIENCE_STORE"
                    ? "편의점"
                    : orderDetail?.data.product.category ===
                      "CHICKEN_PIZZA_BURGER"
                    ? "치킨,피자,버거"
                    : orderDetail?.data.product.category === "EAT_OUT"
                    ? "외식"
                    : orderDetail?.data.product.category === "DONATION"
                    ? "후원"
                    : orderDetail?.data.product.category === "REWARD_EXCHANGE"
                    ? "재화 교환 방식"
                    : "코드 오류"}
                </Element>
              </LabelContainer>
              <LabelContainer>
                <Label>일시</Label>
                <Element>{formatDate(orderDetail?.data.orderedAt)}</Element>
              </LabelContainer>
              <LabelContainer>
                <Label></Label>
                <Element>{`${formatDate(orderDetail?.data.exchangedAt)} (${
                  orderDetail?.data.exchanged ? "발송완료" : "미발송"
                })`}</Element>
              </LabelContainer>
            </InfoContainer>
          </div>
          <SubTitle style={{ marginTop: "40px" }}>상세 설명</SubTitle>
          <Description>{orderDetail?.data.product.description}</Description>
        </OrderInfo>
        <ConsumerInfo>
          <SubTitle>구매자정보</SubTitle>
          <LabelContainer>
            <Label>구매자</Label>
            <Element>{orderDetail?.data.consumer.nickname}</Element>
          </LabelContainer>
          <LabelContainer>
            <Label>번호</Label>
            <Element>{orderDetail?.data.consumer.phone}</Element>
          </LabelContainer>
          <LabelContainer>
            <Label>레벨</Label>
            <Element>{orderDetail?.data.consumer.level}</Element>
          </LabelContainer>
          <LabelContainer>
            <Label>티어</Label>
            <Element>{orderDetail?.data.consumer.tier}</Element>
          </LabelContainer>
          <LabelContainer>
            <Label>포인트</Label>
            <Element>{orderDetail?.data.consumer.points}</Element>
          </LabelContainer>
          {orderDetail?.data.product.cashierCategory === "MANUAL" && (
            <SendBtn onClick={onClickSend}>발송 완료 처리</SendBtn>
          )}
        </ConsumerInfo>
      </Inner>
    </Wrapper>
  );
}
