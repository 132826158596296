import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

interface MultipleSelectChipProps {
  checkOptions: string[];
  onCheckOptionsChange: (newOptions: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const options = [
  { value: "PC", label: "PC" },
  { value: "MOBILE", label: "모바일" },
  { value: "CONSOLE", label: "콘솔" },
  { value: "VR", label: "VR" },
];

export default function MultipleSelectChipPlatform({
  checkOptions,
  onCheckOptionsChange,
}: MultipleSelectChipProps) {
  const handleChange = (event: SelectChangeEvent<typeof checkOptions>) => {
    const {
      target: { value },
    } = event;
    const newOptions = typeof value === "string" ? value.split(",") : value;
    onCheckOptionsChange(newOptions);
  };

  return (
    <div>
      <FormControl sx={{ width: 400 }}>
        <InputLabel id="demo-multiple-chip-label">플랫폼</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={checkOptions}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "0.5" }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    options.find((option) => option.value === value)?.label
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
