import instance from "API/MainAPI";

export function GalleryTestPhaseOneAPI(
  page: number,
  filter: any,
  galleryId: any
) {
  return instance.get("/v3/admins/testers/managements/activities/surveys", {
    params: {
      game: filter.gameId,
      user: filter.userId,
      status: filter.status == null ? null : filter.status,
      pageNumber: page - 1,
      phase: "PHASE_1",
      galleryId,
    },
  });
}
