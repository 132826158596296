import instance from "API/MainAPI";

export default function GalleryShopListAPI(page: number) {
  return instance.get("/v3/admins/gifticons/managements", {
    params: {
      pageSize: 10,
      pageNumber: page - 1,
      eventHost: "GALLERY",
    },
  });
}
