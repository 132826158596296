import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { GalleryTestPhaseOneAPI } from "./GalleryTestPhaseOneAPI";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as PicturePreivewSvg } from "assets/images/PicturePreview.svg";
import ImagePopup from "./Popup/ImagePopup";
import TestPopup from "./Popup/TestPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import TestRejectAPI from "./Data/TestRejectAPI";
import TestApprovalAPI from "./Data/TestApprovalAPI";
import UserPopup from "./Popup/UserPopup";
import StatusPopup from "./Popup/StatusPopup";
import ApproveModal from "./Modal/ApproveModal";
import RejectModal from "./Modal/RejectModal";
import TestManageOneAllCheck from "components/pages/TestManage/TestManagePhaseOneList/TestManageOneAllCheck";
import TestManageOneOnlyCheck from "components/pages/TestManage/TestManagePhaseOneList/TestManageOneOnlyCheck";
import Pagination from "react-js-pagination";
import GameDownload from "components/common/Modal/DownloadList/GameDownload";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  color: #2282e9;
  font-size: 28px;
  font-weight: 700;
`;

const AllList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 33px;
  border: 1px dashed #2282e9;
  color: #2282e9;
  border-radius: 5px;
  cursor: pointer;
`;

const DownLoad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 33px;
  border: 1px dashed #2282e9;
  color: #2282e9;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f9ff;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 6px;
`;

const TableHeaderRow = styled.tr`
  background: #333742;
  border-radius: 4px;
  & th {
    font-size: 20px;
    font-weight: 600;
    color: white;
    padding: 15px 12px 17px;
  }
`;

const Row = styled.tr<{ $isActive?: boolean }>`
  & td {
    height: 53px;
    font-size: 16px;
    font-weight: 400;
    background-color: ${(props) => (props.$isActive ? "#F8F8F8" : "#F0F9FF")};
  }
`;

const PreviewButton = styled.button`
  outline: 0;
  border: 0;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
  display: flex;
  cursor: pointer;
  color: #2282e9;
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 30px;
  justify-content: flex-end;
  font-weight: 600;
`;

const Button = styled.button`
  width: 172px;
  height: 51px;
  border: 1px solid #2282e9;
  color: #2282e9;
  border-radius: 10px;
  cursor: pointer;
`;

const RejectButton = styled(Button)`
  background-color: #fff;
`;

const ConfirmButton = styled(Button)`
  background-color: #f0f9ff;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

interface Filter {
  userId: number | null;
  gameId: number | null;
  status: String | null;
}

interface Item {
  activityId: number;
  testerId: number;
  nickname: string;
  game: string;
  snapshotUrl: string;
  playTime: number;
  submittedAt: string;
  category: string;
  status: string;
  confirmed: boolean;
}

const columns: Array<GalleryTestPhaseOneColumn> = [
  {
    header: "전체선택",
    accessor: "checked",
  },
  {
    header: "유저",
    accessor: "user",
  },
  {
    header: "테스트 게임",
    accessor: "game",
  },
  {
    header: "제출 일시",
    accessor: "submittedAt",
  },
  {
    header: "인증사진",
    accessor: "imageUrl",
  },
  {
    header: "인증상태",
    accessor: "status",
  },
  {
    header: "반려사유",
    accessor: "rejectionReason",
  },
];

export default function GalleryTestManagePhaseOneList() {
  const param = useParams();
  const [pageNum, setPageNum] = useState(1);
  const [testList, setTestList] = useState<any>([]);
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = { userId: null, gameId: null, status: null };
    return savedFilter;
  });

  const [popupState, setPopupState] = useState({
    isOpenPopupTest: false,
    isOpenPopupUser: false,
    isOpenPopupImage: false,
    isOpenPopupConfirmed: false,
    isOpenApprovePopup: false,
    isOpenRejectPopup: false,
    isOpenDownloadPopup: false,
  });

  let length: number = 0;

  const { data, refetch } = useQuery(
    "galleryPhase1",
    async () => {
      return await GalleryTestPhaseOneAPI(pageNum, filter, param.galleryId);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        length = res?.data.page.content.length;
        const checkbox_state_arr = new Array(length).fill(false);
        setOneCheckBoxChecked(checkbox_state_arr);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setTestList(data);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    setPageNum(1); //필터링할 때 마다 페이지 1로 초기화
  }, [filter]);

  useEffect(() => {
    refetch();
  }, [filter, pageNum]);

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState<boolean>(false);
  const [oneCheckBoxChecked, setOneCheckBoxChecked] = useState<boolean[]>([]);

  //전체 선택 체크박스 핸들러
  const handleAllCheckBox = () => {
    const newAllCheckBoxChecked = !allCheckBoxChecked;
    setAllCheckBoxChecked(newAllCheckBoxChecked);
    // console.log(allCheckBoxChecked);
    const checkHandlerArray = new Array(oneCheckBoxChecked.length).fill(
      newAllCheckBoxChecked
    );
    setOneCheckBoxChecked(checkHandlerArray);
  };

  //각 항목 체크박스 핸들러
  const handleOneCheckBox = (index: number) => {
    oneCheckBoxChecked[index] = !oneCheckBoxChecked[index];
    setOneCheckBoxChecked([...oneCheckBoxChecked]);
  };

  //체크박스 선택된 데이터
  const selectedArray = useMemo(() => {
    return (testList?.data?.page.content as Item[] | undefined)?.filter(
      (item, idx) => oneCheckBoxChecked[idx]
    );
  }, [oneCheckBoxChecked, testList]);
  const activityIdinArray = selectedArray?.map((item) => item.activityId);

  //첨부사진 관련 state와 handler
  const [currentIdx, setCurrentIdx] = useState<number>();
  const [snapShot, setSnapShot] = useState<String>("");
  const [userId, setUserId] = useState<String>();
  const openImagePopUp = (index: number) => {
    console.log(index);
    console.log(testList?.data?.page?.content[index]);
    if (testList && index >= 0) {
      setSnapShot(testList?.data?.page?.content[index].snapshotUrl);
      setUserId(testList?.data?.page?.content[index].activityId.toString());
      setPopupState({
        ...popupState,
        isOpenPopupImage: true,
      });
    }
  };

  useEffect(() => {
    if (currentIdx !== undefined && currentIdx !== null) {
      openImagePopUp(currentIdx);
    }
  }, [currentIdx]);

  // 다음 팝업으로 넘기기
  const shiftRight = () => {
    setCurrentIdx((prevIndex) => {
      if (prevIndex === undefined || prevIndex === null) {
        return 0;
      }
      return (prevIndex + 1) % (testList?.data?.page?.content.length || 1);
    });
  };

  // 이전 팝업으로 넘기기
  const shiftLeft = () => {
    setCurrentIdx((prevIndex) => {
      if (prevIndex === undefined || prevIndex === null) {
        return 0;
      }
      return prevIndex === 0
        ? (testList?.data?.page?.content.length || 1) - 1
        : prevIndex - 1;
    });
  };

  //승인&거절하기
  const onApproval = async (activityIds: number, count: number) => {
    const body = {
      activityIds: activityIds,
      count: count,
    };
    await TestApprovalAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenApprovePopup: false,
        });
        refetch();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  const onRejection = async (
    activityIds: number,
    count: number,
    reason: string
  ) => {
    const body = {
      activityIds: activityIds,
      count: count,
      reason: reason,
    };
    await TestRejectAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenRejectPopup: false,
        });
        refetch();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  const renderHeader = (column: GalleryTestPhaseOneColumn) => {
    switch (column.accessor) {
      case "checked":
        return (
          <div
            style={{
              display: "flex",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            <TestManageOneAllCheck
              checked={allCheckBoxChecked}
              onChange={handleAllCheckBox}
            />
            {column.header}
          </div>
        );
      case "game":
        return (
          <div
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenPopupTest: true,
              });
            }}
          >
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "8px" }} />
          </div>
        );
      case "user":
        return (
          <div
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenPopupUser: true,
              });
            }}
          >
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "8px" }} />
          </div>
        );
      case "status":
        return (
          <div
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenPopupConfirmed: true,
              });
            }}
          >
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "8px" }} />
          </div>
        );

      default:
        return column.header;
    }
  };

  return (
    <Wrapper>
      {popupState.isOpenPopupUser && (
        <UserPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupUser: false,
            })
          }
          setUser={(newUser: number) =>
            setFilter((prevState) => ({
              ...prevState,
              userId: newUser,
            }))
          }
        />
      )}
      {popupState.isOpenPopupTest && (
        <TestPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupTest: false,
            })
          }
          setGame={(newGame: number) =>
            setFilter((prevState) => ({
              ...prevState,
              gameId: newGame,
            }))
          }
          galleryId={param.galleryId}
        />
      )}
      {popupState.isOpenPopupImage && (
        <ImagePopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupImage: false,
            })
          }
          snapshotUrl={snapShot}
          id={userId}
          shiftLeft={shiftLeft}
          shiftRight={shiftRight}
        />
      )}
      {popupState.isOpenPopupConfirmed && (
        <StatusPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupConfirmed: false,
            })
          }
          setValue={(newStatus: String) =>
            setFilter((prevState) => ({
              ...prevState,
              status: newStatus,
            }))
          }
        />
      )}
      {popupState.isOpenApprovePopup && (
        <ApproveModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenApprovePopup: false,
            })
          }
          callAPI={onApproval}
          selectArr={activityIdinArray}
        />
      )}
      {popupState.isOpenRejectPopup && (
        <RejectModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenRejectPopup: false,
            })
          }
          callAPI={onRejection}
          selectArr={activityIdinArray}
        />
      )}
      {popupState.isOpenDownloadPopup && (
        <GameDownload
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenDownloadPopup: false,
            })
          }
          search={filter.gameId}
          type={"game"}
          surveyCategory={"GALLERY"}
        />
      )}
      <HeaderContainer>
        <Title>테스트 인증 관리</Title>
        <AllList
          onClick={() => {
            setFilter({
              userId: null,
              gameId: null,
              status: null,
            });
          }}
        >
          전체 목록 보기
        </AllList>
        {filter.gameId !== null && (
          <DownLoad
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenDownloadPopup: true,
              });
            }}
          >
            설문 다운로드
          </DownLoad>
        )}
      </HeaderContainer>
      <div>
        <Table>
          <thead>
            <TableHeaderRow>
              {columns.map((column) => (
                <th key={column.accessor}>{renderHeader(column)}</th>
              ))}
            </TableHeaderRow>
          </thead>
          <tbody>
            {testList &&
              testList?.data?.page?.content.map((data: any, index: number) => (
                <Row $isActive={data.confirmed}>
                  <td>
                    <TestManageOneOnlyCheck
                      index={index}
                      checked={oneCheckBoxChecked}
                      onChange={() => handleOneCheckBox(index)}
                      disabled={data.confirmed}
                    />
                  </td>
                  <td>{data.nickname}</td>
                  <td>{data.game}</td>
                  <td>
                    {data.submittedAt &&
                      new Date(data.submittedAt).toLocaleString()}
                  </td>
                  {data.confirmed ? (
                    <td>
                      <PreviewButton>
                        <PicturePreivewSvg style={{ color: "#c0c0c0" }} />
                      </PreviewButton>
                    </td>
                  ) : (
                    <td>
                      <PreviewButton
                        onClick={() => {
                          setCurrentIdx(index);
                        }}
                      >
                        <PicturePreivewSvg />
                      </PreviewButton>
                    </td>
                  )}
                  <td>
                    {data.status === "PENDING"
                      ? "대기"
                      : data.status === "REJECTED"
                      ? "거절"
                      : data.status === "APPROVED"
                      ? "승인"
                      : "심사중"}
                  </td>
                  <td>{data.rejectionMessage}</td>
                </Row>
              ))}
          </tbody>
        </Table>
      </div>
      <BottomDiv>
        <ButtonDiv>
          <RejectButton
            type="button"
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenRejectPopup: true,
              });
            }}
          >
            거절
          </RejectButton>
          <ConfirmButton
            type="button"
            onClick={() => {
              setPopupState({
                ...popupState,
                isOpenApprovePopup: true,
              });
            }}
          >
            승인
          </ConfirmButton>
        </ButtonDiv>
        <PaginationWrapper>
          <Pagination
            activePage={pageNum}
            itemsCountPerPage={10}
            totalItemsCount={testList?.data?.page?.pageable.totalElements || 1}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            onChange={handlePageChange}
          />
        </PaginationWrapper>
        {testList?.data?.page?.count == 0 && (
          <NoneData>검색 결과 없음</NoneData>
        )}
      </BottomDiv>
    </Wrapper>
  );
}
