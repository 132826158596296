import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GalleryNotificationListAPI from "./Data/GalleryNotificationListAPI";
import Pagination from "react-js-pagination";
import { Category } from "components/common/function/category";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
`;

const Table = styled.div`
  width: 100%;
  text-align: center;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  background-color: #2282e9;
  color: #fff;
  height: 54px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const HeaderTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`;

const TBodyTr = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  border: 2px solid #f1f1f1;
  height: 64px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const Td = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15;
`;

const DetailButton = styled.button`
  width: 126px;
  height: 43px;
  background-color: #f0f9ff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  border: none;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

export default function GalleryNotificationList() {
  const pageNum = sessionStorage.getItem("galleryNotificationPage") || "1";

  const { data: notifications, refetch } = useQuery(
    ["galleryNotifications", pageNum],
    async () => {
      return await GalleryNotificationListAPI("GALLERY", Number(pageNum), 10);
    },
    {
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("galleryNotificationPage", pageNumber.toString());
    refetch();
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <Title>알림 내역 확인</Title>
        <Link to="/gallery/notifications" style={{ textDecoration: "none" }}>
          <Button>알림 전송 페이지</Button>
        </Link>
      </HeaderContainer>
      <Table>
        <THead>
          <HeaderTh>No.</HeaderTh>
          <HeaderTh>메세지</HeaderTh>
          <HeaderTh>분류</HeaderTh>
          <HeaderTh>제목</HeaderTh>
          <HeaderTh>유저</HeaderTh>
        </THead>
        {notifications?.data?.page?.content?.map(
          (notification: any, index: number) => {
            const category = Category(notification.category);
            return (
              <TBodyTr key={notification.id}>
                <Td>{notification.id}</Td>
                <Td>{notification.message}</Td>
                <Td>{category}</Td>
                <Td>{notification.title}</Td>
                <Td>{notification.user.nickname}</Td>
              </TBodyTr>
            );
          }
        )}
      </Table>
      <PaginationWrapper>
        <Pagination
          activePage={Number(pageNum)}
          itemsCountPerPage={10}
          totalItemsCount={
            notifications?.data?.page?.pageable.totalElements || 1
          }
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
