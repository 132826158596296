import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import GalleryBannerDetailAPI from "./Data/GalleryBannerDetailAPI";
import GalleryBannerDeleteAPI from "./Data/GalleryBannerDeleteAPI";
import { clearAll } from "components/common/clearStorage";
import GalleryBannerModificationAPI from "./Data/GalleryBannerModificationAPI";
import EditModal from "./Modal/EditModal";
import DeleteModal from "./Modal/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  background-color: #2282e9;
  color: #fff;
`;

const EditButton = styled(Button)`
  background-color: #2282e9;
  color: #fff;
`;

const DeleteButton = styled(Button)`
  background-color: #fff;
  color: #2282e9;
  border: 1px solid #2282e9;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const Img = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 22px;
`;

const Comment = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #262626;
`;

const Input = styled.input`
  width: 470px;
  height: 60px;
  border: 2px solid #2282e9;
  border-radius: 6px;
  padding: 0px 20px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 513px;
  height: 60px;
  border-radius: 6px;
  border: 2px solid #2282e9;
  font-weight: 500;
  font-size: 18px;
  padding: 0px 20px;
  color: #262626;
  appearance: none;
  outline: none;
`;

export default function GalleryBannerDetail() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const [image, setImage] = useState<File | undefined>();
  const formData = new FormData();
  const showLink = watch("redirectionCategory");

  const { data: adsDetail } = useQuery(["adsDetail", id], () =>
    GalleryBannerDetailAPI(id)
  );

  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  function onDelete() {
    setModalDelete(true);
  }
  function closeDelete() {
    setModalDelete(false);
  }

  function onEdit() {
    const redirectLinkValue = getValues("redirectLink");
    if (showLink === "EXTERNALS") {
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else {
        setModalEdit(true);
      }
    } else {
      setValue("redirectLink", "");
      setModalEdit(true);
    }
  }

  function closeEdit() {
    setModalEdit(false);
  }

  const { mutate: deleteAds, isLoading: deleteLoading } = useMutation(
    async () => await GalleryBannerDeleteAPI(id),
    {
      onMutate: async () => {
        // Remove the query from cache before making the delete request
        queryClient.removeQueries(["GalleryAdsDetail", id]);
      },
      onSuccess: () => {
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const handleDelete = async () => {
    await deleteAds();
    await alert("배너가 삭제되었습니다.");
    await navigate(-1);
  };

  async function CallEditAPI() {
    const titleValue = getValues("title");
    const descriptionValue = "";
    // 배너 설명 추가시 사진 위에 글자가 생성됨
    const redirectLinkValue = getValues("redirectLink");
    const visibleValue = getValues("visible");
    const categoryValue = getValues("category");
    const redirectionCategoryValue = getValues("redirectionCategory");

    const modifiedData = {
      title: titleValue !== undefined ? titleValue : adsDetail?.data.title,
      description:
        descriptionValue !== undefined
          ? descriptionValue
          : adsDetail?.data.description,
      redirectLink:
        redirectLinkValue !== undefined
          ? redirectLinkValue
          : adsDetail?.data.redirectLink,
      visible:
        visibleValue !== undefined ? visibleValue : adsDetail?.data.visible,
      category:
        categoryValue !== undefined ? categoryValue : adsDetail?.data.category,
      redirectionCategory:
        redirectionCategoryValue !== undefined
          ? redirectionCategoryValue
          : adsDetail?.data.redirectionCategory,
    };

    const blob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", blob);
    if (image) {
      formData.append("photo", image);
    }

    await adsEdit.mutateAsync(formData);
  }

  const adsEdit = useMutation(
    (formData: FormData) => GalleryBannerModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("배너 수정이 완료되었습니다.");
        queryClient.invalidateQueries("adsDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await GalleryBannerDetailAPI(id);
      if (response) {
        setValue("title", response.data.title);
        setValue("description", response.data.description);
        setValue("redirectLink", response.data.redirectLink);
        setValue("visible", response.data.visible);
        setValue("category", response.data.category);
        setValue("redirectionCategory", response.data.redirectionCategory);
      }
    };

    fetchData();
  }, []);

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
    };
  }
  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={handleDelete}
          isLoding={deleteLoading}
        />
      )}
      <HeaderContainer>
        <Title>배너 관리</Title>
        <div style={{ display: "flex", gap: "12px" }}>
          <DeleteButton onClick={onDelete}>삭제하기</DeleteButton>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </div>
      </HeaderContainer>
      <ContentContainer>
        <div>
          <div style={{ width: "500px" }}>
            {image ? (
              <Img src={URL.createObjectURL(image)} alt="" />
            ) : (
              <Img src={adsDetail?.data.bannerUrl} alt="" />
            )}
          </div>
          <input type="file" accept=".jpg, .png" onChange={Image} />
        </div>
        <InfoContainer>
          <LabelContainer>
            <div style={{ display: "flex", gap: "20px" }}>
              <Label>배너 이름</Label>
              <Comment>*필수 아님</Comment>
            </div>
            <Input
              type="text"
              defaultValue={adsDetail?.data.title}
              {...register("title")}
            />
          </LabelContainer>
          <LabelContainer>
            <Label>카테고리</Label>
            <SelectBoxContainer>
              <SelectBox {...register("category")}>
                <option value={"GALLERY_BANNER"}>
                  온라인 전시관 메인 페이지 배너
                </option>
                <option value={"GALLERY_BANNER_WEB"}>
                  온라인 전시관 메인 페이지 배너(web)
                </option>
                <option value={"GALLERY_POINT_SHOP_BANNER"}>
                  온라인 전시관 포인트샵 배너
                </option>
                <option value={"GALLERY_POINT_SHOP_BANNER_WEB"}>
                  온라인 전시관 포인트샵 배너(web)
                </option>
              </SelectBox>
              <FontAwesomeIcon
                icon={faPlay}
                style={{
                  rotate: "90deg",
                  color: "#2282e9",
                  position: "absolute",
                  top: "22px",
                  right: "17px",
                }}
              />
            </SelectBoxContainer>
          </LabelContainer>
          <LabelContainer>
            <Label>연결 링크 카테고리</Label>
            <SelectBoxContainer>
              <SelectBox {...register("redirectionCategory")}>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="DAY_MISSION">데이 미션</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </SelectBox>
              <FontAwesomeIcon
                icon={faPlay}
                style={{
                  rotate: "90deg",
                  color: "#2282e9",
                  position: "absolute",
                  top: "22px",
                  right: "17px",
                }}
              />
            </SelectBoxContainer>
          </LabelContainer>
          {showLink == "EXTERNALS" && (
            <LabelContainer>
              <div style={{ display: "flex", gap: "20px" }}>
                <Label>연결 링크</Label>
                <Comment>*필수 아님</Comment>
              </div>
              <Input
                type="text"
                defaultValue={adsDetail?.data.redirectLink}
                {...register("redirectLink")}
              />
            </LabelContainer>
          )}
          <LabelContainer>
            <Label>어플 표시</Label>
            <div style={{ display: "flex", gap: "96px" }}>
              <div>
                <input
                  type="radio"
                  value="true"
                  checked={visibleValue === true}
                  {...register("visible")}
                  onChange={handleRadioChange}
                />
                <label>보이기</label>
              </div>
              <div>
                <input
                  type="radio"
                  value="false"
                  checked={visibleValue === false}
                  {...register("visible")}
                  onChange={handleRadioChange}
                />
                <label>숨기기</label>
              </div>
            </div>
          </LabelContainer>
        </InfoContainer>
      </ContentContainer>
    </Wrapper>
  );
}
