import { useQuery } from "react-query";
import styled from "styled-components";
import GalleryBannerListAPI from "./Data/bannerListAPI";
import { bannerCategoryText } from "components/common/function/bannerCategory";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
`;

const Table = styled.div`
  width: 100%;
  text-align: center;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  background-color: #2282e9;
  color: #fff;
  height: 54px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const HeaderTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`;

const TBodyTr = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  border: 2px solid #f1f1f1;
  height: 64px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const Td = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15;
`;

const DetailButton = styled.button`
  width: 126px;
  height: 43px;
  background-color: #f0f9ff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  border: none;
`;

export default function GalleryBannerList() {
  const { data: ads } = useQuery(
    ["ads"],
    async () => await GalleryBannerListAPI()
  );

  return (
    <Wrapper>
      <HeaderContainer>
        <Title>배너관리</Title>
        <Link to={"add"} style={{ textDecoration: "none" }}>
          <Button>등록</Button>
        </Link>
      </HeaderContainer>
      <Table>
        <THead>
          <HeaderTh>No.</HeaderTh>
          <HeaderTh>배너명</HeaderTh>
          <HeaderTh>카테고리</HeaderTh>
          <HeaderTh>공개여부</HeaderTh>
          <HeaderTh />
        </THead>
        {ads?.data?.contents.map((banner: any, index: number) => {
          return (
            <TBodyTr className="tableH-50" key={banner.id}>
              <Td>{index + 1}</Td>
              <Td>{banner.title}</Td>
              <Td>{bannerCategoryText(banner.category)}</Td>
              <Td>{banner.visible === true ? "보임" : "숨김"}</Td>
              <Td>
                <Link
                  to={"/gallery/banner/" + banner.id}
                  state={{
                    id: banner.id,
                  }}
                >
                  <DetailButton className="detailBtn">자세히</DetailButton>
                </Link>
              </Td>
            </TBodyTr>
          );
        })}
      </Table>
    </Wrapper>
  );
}
