import instance from "API/MainAPI";

export default function GalleryManagementAPI(id: any, formData: any) {
  return instance.post(`/v3/admins/gifticons/${id}/codes`, formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
