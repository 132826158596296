import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
`;

const AlertTest = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;
  left: 4%;
  top: 15%;
  width: 250px;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 5px;
`;

const Contents = styled.div`
  padding-bottom: 20px;
  margin: 1rem;
`;

const Cursor = styled.div`
  display: flex;
`;

const Txt = styled.div`
  font-weight: 600;
  margin-right: 0.5rem;
`;

const CloseBtn = styled.div`
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;
  display: flex;
  margin-top: -0.2rem;
`;

const Content = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  color: #555555;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  font-size: 14px;
`;

function StatusPopup(props: any) {
  return (
    <div>
      <Wrapper>
        <AlertTest>
          <Header>
            <Cursor>
              <Txt>분류</Txt>
            </Cursor>
            <CloseBtn onClick={props.onClose}>
              <FontAwesomeIcon icon={faX} />
            </CloseBtn>
          </Header>

          <Contents>
            <div>
              {
                <Content
                  onClick={() => {
                    props.setValue("REWARD_EXCHANGE");
                    props.onClose();
                  }}
                >
                  <Title>재화교환</Title>
                </Content>
              }
              {
                <Content
                  onClick={() => {
                    props.setValue("KAKAO_BIZ");
                    props.onClose();
                  }}
                >
                  <Title>카카오 발송</Title>
                </Content>
              }
              {
                <Content
                  onClick={() => {
                    props.setValue("CODE");
                    props.onClose();
                  }}
                >
                  <Title>쿠폰코드 발송</Title>
                </Content>
              }
              {
                <Content
                  onClick={() => {
                    props.setValue("MANUAL");
                    props.onClose();
                  }}
                >
                  <Title>관리자 직접 발송</Title>
                </Content>
              }
            </div>
          </Contents>
        </AlertTest>
      </Wrapper>
    </div>
  );
}

export default StatusPopup;
