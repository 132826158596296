import instance from "API/MainAPI";

export default async function GalleryShopExcelDownloadAPI() {
  const res = await instance.get(
    `/gifticon-code-example.xlsx
`,
    {
      responseType: "blob",
    }
  );
  return res.data;
}
