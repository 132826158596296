import instance from "API/MainAPI";

export default function GalleryNotificationListAPI(
  query: string,
  pageNumber: number,
  pageSize: number
) {
  return instance.get("/v1/admins/notifications/managements", {
    params: {
      pageSize,
      pageNumber: pageNumber - 1,
      query,
    },
  });
}
