import instance from "API/MainAPI";

export default function GallerySurveyModificationAPI(id: any, formData: any) {
  return instance.patch(
    `/v3/admins/surveys/managements/questions/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
