import instance from "API/MainAPI";

export default function GalleryShopModificationKakaoBizAPI(
  id: any,
  formData: any
) {
  return instance.patch(
    `/v3/admins/gifticons/managements/kakao/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
