import instance from "API/MainAPI";

export default function GalleryShopCreationKakaoBizAPI(bodyFormData: any) {
  return instance.post("/v3/admins/gifticons/managements/kakao", bodyFormData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
