import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GalleryManagementListAPI from "./Data/GalleryManagementListAPI";
import formatDate from "components/common/function/formatDate";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  width: 199px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  background-color: #2282e9;
  color: #fff;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;
`;

const Content = styled(Link)`
  width: 100%;
  height: 326px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 191px;
`;

const TotTester = styled.div`
  width: 100%;
  height: 40px;
  background-color: #2282e9;
  margin-top: -5px;
  color: white;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
`;

const GalleryTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #2282e9;
  margin-bottom: 10px;
`;

const DateSpan = styled.span`
  font-weight: 500;
  color: #8e8e8e;
`;

const Info = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`;

export default function GalleryManagement() {
  const { data: galleryList } = useQuery(
    ["galleryManagement"],
    async () => await GalleryManagementListAPI()
  );
  return (
    <Wrapper>
      <HeaderContainer>
        <Title>전시관 관리</Title>
        <Link to="add" style={{ textDecoration: "none" }}>
          <Button>온라인 전시관 등록</Button>
        </Link>
      </HeaderContainer>
      <ContentContainer>
        {galleryList?.data?.page?.content?.map((gallery: any) => (
          <Content
            key={gallery.id}
            to={`${gallery.id}/games`}
            state={{
              id: gallery.id,
              title: gallery.title,
              startedAt: gallery.startedAt,
              expiredAt: gallery.expiredAt,
            }}
          >
            <Image src={gallery.bannerUrl}></Image>
            <TotTester>
              <span>참여수</span>
              <span style={{ fontWeight: "700", fontSize: "16px" }}>
                {gallery.totTesters}명
              </span>
            </TotTester>
            <Info>
              <GalleryTitle>{gallery.title}</GalleryTitle>
              <DateSpan>
                {formatDate(gallery.startedAt)} -{" "}
                {formatDate(gallery.expiredAt)}
              </DateSpan>
            </Info>
          </Content>
        ))}
      </ContentContainer>
    </Wrapper>
  );
}
