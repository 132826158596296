import { useQuery } from "react-query";
import styled from "styled-components";
import GalleryManagementGameListAPI from "./Data/GalleryManagementGameListAPI";
import { Link, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Button = styled.div`
  width: 199px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
`;

const EditBtn = styled(Button)`
  background-color: #2282e9;
  color: #fff;
`;

const AddBtn = styled(Button)`
  background-color: #fff;
  color: #2282e9;
  border: 1px solid #2282e9;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 22px;
  row-gap: 24px;
`;

const Content = styled(Link)`
  width: 100%;
  height: 255px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 151px;
`;

const TotTester = styled.div`
  width: 100%;
  height: 40px;
  background-color: #2282e9;
  margin-top: -5px;
  color: white;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;

const Info = styled.div`
  padding: 14px 30px;
  display: flex;
  flex-direction: column;
`;

const GameTitle = styled.span`
  font-weight: 600;
  color: #262626;
  margin-bottom: 4px;
`;

const Company = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #c0c0c0;
`;

export default function GalleryManagementGameList() {
  const location = useLocation();
  const galleryId = location.state.id;
  const title = location.state.title;
  const startedAt = location.state.startedAt;
  const expiredAt = location.state.expiredAt;
  const { data: gameList } = useQuery(
    ["galleryGameManagement"],
    async () => await GalleryManagementGameListAPI(galleryId)
  );
  return (
    <Wrapper>
      <HeaderContainer>
        <Title>{title}</Title>
        <ButtonContainer>
          <Link
            to="/gallery/notifications"
            state={{
              galleryId: galleryId,
            }}
            style={{ textDecoration: "none" }}
          >
            <AddBtn>알림 전송</AddBtn>
          </Link>
          <Link
            to="add"
            state={{
              galleryId: galleryId,
              startedAt: startedAt,
              expiredAt: expiredAt,
            }}
            style={{ textDecoration: "none" }}
          >
            <AddBtn>게임 등록</AddBtn>
          </Link>
          <Link
            to={`/gallery/management/${galleryId}`}
            style={{ textDecoration: "none" }}
            state={{ id: galleryId }}
          >
            <EditBtn>전시관 수정</EditBtn>
          </Link>
        </ButtonContainer>
      </HeaderContainer>
      <Container>
        {gameList?.data?.page?.content?.map((game: any) => (
          <Content
            key={game.id}
            to={`${game.id}`}
            state={{
              galleryId: galleryId,
              gameId: game.id,
              startedAt: startedAt,
              expiredAt: expiredAt,
            }}
          >
            <Image src={game.bannerUrl}></Image>
            <TotTester>
              <div style={{ display: "flex", gap: "6px" }}>
                <span>참여수</span>
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {game.totTesters}명
                </span>
              </div>
              <div style={{ display: "flex", gap: "6px" }}>
                <span>완료수</span>
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {game.totCompletions}명
                </span>
              </div>
            </TotTester>
            <Info>
              <GameTitle>{game.title}</GameTitle>
              <Company>{game.company}</Company>
            </Info>
          </Content>
        ))}
      </Container>
    </Wrapper>
  );
}
