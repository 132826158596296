import { faArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 37px 60px 34px 60px;
  font-size: 23px;
  position: relative;
`;
const Survey = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;

  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;

  height: 800px;
  top: 7%;
  width: 931px;
  overflow: scroll;
`;
const Block = styled.div`
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;

const Txt = styled.span`
  padding-bottom: 11px;
  display: block;
`;
const BigTxt = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #2282e9;
  margin-bottom: 14px;
  display: block;
`;

const Contents = styled.div`
  padding: 30px 60px 40px 60px;
  overflow: scroll;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #2282e9;
`;

const Hr = styled.div`
  width: 100%;
  height: 2px;
  background-color: #c9e8fe;
`;

const AnswerContainer = styled.div`
  background-color: #f8f8f8;
  margin-bottom: 14px;
  border-radius: 10px;
  color: #555555;
  padding: 10px 20px 0px 20px;
  font-weight: 500;
`;

function SurveyPopup(props: any) {
  console.log(props);
  return (
    <div>
      <Wrapper>
        <Survey>
          <Header>
            <Title>설문 자세히 보기</Title>
            <CloseBtn onClick={props.onClose}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: "#828282" }}
              />
            </CloseBtn>
          </Header>
          <Hr />
          <Contents>
            <Block>
              <BigTxt>객관식</BigTxt>

              {props.multiple !== undefined &&
                props.multiple.map((item: any, index: number) => {
                  const filteredChoices = item.choices.filter(
                    (itemin: any) => itemin.archive === true
                  );
                  return (
                    <AnswerContainer key={index}>
                      <Txt>
                        Q{index + 1}. {item.content}
                      </Txt>
                      {filteredChoices.length > 0 ? (
                        filteredChoices.map(
                          (trueItems: any, trueindex: number) => (
                            <Txt key={trueindex}>
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{ marginRight: "8px" }}
                              />
                              {trueItems.content}
                            </Txt>
                          )
                        )
                      ) : (
                        <Txt>답변 없음</Txt>
                      )}
                    </AnswerContainer>
                  );
                })}
            </Block>
            <Block>
              <BigTxt>주관식</BigTxt>
              {props.subjective !== undefined &&
                props.subjective.map((item: any, index: number) => {
                  return (
                    <AnswerContainer key={index}>
                      <Txt>Q. {item.content}</Txt>
                      {!item.archiveResponse ? (
                        <Txt>답변없음</Txt>
                      ) : (
                        <Txt>
                          {" "}
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            style={{ marginRight: "8px" }}
                          />
                          {item.archiveResponse}
                        </Txt>
                      )}
                    </AnswerContainer>
                  );
                })}
            </Block>
          </Contents>
        </Survey>
      </Wrapper>
    </div>
  );
}

export default SurveyPopup;
